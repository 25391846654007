import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { MdExitToApp, MdEditSquare ,MdCancel, MdArrowBack } from "react-icons/md";
import CustomClipperLoader from "../../../hooks/CustomClipperLoader";

export default function Settings({ Parse }) {
  const navigate = useNavigate();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const userLogInformations = useSelector(
    (state) => state.authReducer.userInfo
  );

  const logOut = async () => {
    const currentUser = Parse.User.current();
    if (currentUser) {
      Parse.User.logOut();
    }
  };

  const deleteAccount = async () => {
    setIsLoggingOut(true);
    logOut();
    await axios.post(`${process.env.REACT_APP_NODEJS_URL}/deleteUserData`, {
      id: userLogInformations.id,
    });
    navigate("/login");
  };

  const handleDelete = () => {
    const confirmation = window.confirm(
      "Sei sicuro di voler eliminare il tuo account?"
    );
    if (confirmation) {
      deleteAccount();
    }
  };

  return (
    <div style={{ margin: "10px" }}>
      <div style={{display:'flex', flexDirection:'row'}}>
      <div
          style={{ marginRight: "20px", display: "flex", alignItems: "center" }}
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBack size={30} />
        </div>
        <h2>Settings</h2>
        </div>
      {isLoggingOut && <CustomClipperLoader />}
      {!isLoggingOut && (
        <div style={{ marginTop: "20px" }}>
          <div
            className="settingOption"
            onClick={() => {
              navigate("/editInfo");
            }}
          >
            {" "}
            <MdEditSquare />{" "}
            <span style={{ marginLeft: "5px", cursor: "pointer" }}>
              Edit main info
            </span>
          </div>
          <div
            className="settingOption"
            onClick={() => {
              setIsLoggingOut(true);
              logOut();
              navigate("/login");
            }}
          >
            {" "}
            <MdExitToApp />{" "}
            <span style={{ marginLeft: "5px", cursor: "pointer" }}>
              Log Out
            </span>
          </div>
          
          <div
            className="settingOption"
            style={{
              backgroundColor: "#ff0000cc",
              fontWeight: "bold",
              color: "white",
            }}
            onClick={handleDelete}
          >
            {" "}
            <MdCancel />{" "}
            <span style={{ marginLeft: "5px", cursor: "pointer" }}>
              Delete Account
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
