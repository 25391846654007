import React, { useEffect, useRef, useState } from "react";
import "../../../../../App.css";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  MdArrowBack,
  MdArrowForward,
  MdDelete,
  MdDeleteOutline,
  MdOutlineToggleOff,
  MdToggleOn,
} from "react-icons/md";
import { Button, Select, Spin } from "antd";
import RewardDeductionFunction from "../../../../hooks/NcRewardDeductionFunction";
import { fetchPeopleQueryParameters } from "../../../../global-context/Slice";

export default function FiltersPage() {
  const { Option } = Select;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myRef = useRef(true);

  const customSearchNcCost = -150;
  const categories = useSelector((state) => state.fecthCategoriesReducer);
  const [name, setName] = useState(undefined);
  const [isProFiltersOpened, setProFiltersOpened] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedBusinessInterest, setSelectedBusinessInterest] = useState([]);
  const [selectedBusinessExp, setSelectedBusinessExp] = useState([]);
  const [selectedHobbies, setSelectedHobbies] = useState([]);
  const [maxDistance, setMaxDistance] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [pastSearchIsLoading, setPastSearchIsLoading] = useState("");
  const [isProSearch, setProSearch] = useState(false);
  const [isEmptySearch, setEmptySearch] = useState(false);
  const [areThereResults, setAreThereResults] = useState(0);
  const [firstResults, setFirstResults] = useState([]);
  const [mySearches, setMySearches] = useState([]);
  const userData = useSelector((state) => state.fecthUserDataReducer);
  const userId = userData.id;
  const userLat = userData.position.y;
  const userLong = userData.position.x;
  const skills = categories.skills.map((object) => object.items).flat();
  const hobbies = categories.hobbies.map((object) => object.items).flat();
  const businesses = categories.businessTypes
    .map((object) => object.items)
    .flat();

  useEffect(() => {
    setAreThereResults(null);
    setProSearch(false);
  }, [
    name,
    selectedBusinessInterest,
    selectedBusinessExp,
    selectedHobbies,
    selectedSkills,
    maxDistance,
  ]);

  useEffect(() => {
    const getCustomSearches = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_NODEJS_URL}/getCustomSearches`,
          { collectionId: userId }
        );

        const result = Object.values(response.data);
        setMySearches(result);
        myRef.current = false;
      } catch (error) {
        console.error("Failed to fetch posts", error);
      }
    };

    if (myRef.current) {
      getCustomSearches();
    }
  }, []);

  const setIsProSearch = () => {
    if (
      selectedSkills.length > 0 ||
      selectedBusinessInterest.length > 0 ||
      selectedBusinessExp.length > 0 ||
      selectedHobbies.length > 0 ||
      maxDistance > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getFilterObject = () => {
    const filterObject = {
      personal_user_id: userId,
      page: 1,
      limit: 35,
      latitude: userLat,
      longitude: userLong,
      nome: name ? name : null,
      max_distance: maxDistance ? maxDistance > 0 : null,
      skills: selectedSkills,
      businessInterests: selectedBusinessInterest,
      hobbies: selectedHobbies,
      businessExperience: selectedBusinessExp,
    };
    dispatch(fetchPeopleQueryParameters({ getPeopleParams: filterObject }));
    return filterObject;
  };

  const search = async (object) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NODEJS_URL}/getCustomSearchResults`,
        object
      );
      const result = Object.values(response.data);
      return result;
    } catch (error) {
      console.error("Failed to fetch posts", error);
      alert("Something went wrong");
      return [];
    }
  };

  const setResultsFlag = async () => {
    const result = await search(getFilterObject());

    setAreThereResults(result.length);
    if (result.length === 0) {
      setEmptySearch(true);
    } else {
      setEmptySearch(false);
    }
    setIsLoading(false);
    setFirstResults(result);
  };

  const handleSearch = () => {
    setIsLoading(true);
    setResultsFlag();
  };

  const saveResearch = async () => {
    try {
      axios.post(`${process.env.REACT_APP_NODEJS_URL}/saveCustomSearch`, {
        collectionId: userId,
        customSearchInfo: getFilterObject(),
      });
    } catch (error) {
      console.error("Failed to fetch posts", error);
    }
  };

  const goToResultPage = () => {
    navigate("/custom-search-people", {
      state: { firstResults: firstResults },
    });
  };

  const showSearchResults = () => {
    if (setIsProSearch()) {
      setProSearch(true);
      saveResearch();
    } else {
      goToResultPage();
    }
  };

  const handlePastSearch = async (object) => {
    setPastSearchIsLoading(object._id);
    const result = await search(object);
    if (result.length > 0) {
      setPastSearchIsLoading("");
      navigate("/custom-search-people", {
        state: { firstResults: result },
      });
    }
  };

  const deletePastSearch = async (object) => {
    try {
      axios.post(`${process.env.REACT_APP_NODEJS_URL}/deleteCustomSearches`, {
        collectionId: userId,
        documentId: object._id,
      });
    } catch (error) {
      console.error("Failed to delete searches", error);
    }
    console.log(`${object._id} deleted`);
  };

  const handleDeletePastSearch = async (object) => {
    const confirmation = window.confirm(
      "If you delete this search you will not be able to recover it. Are you sure you want delete it ?"
    );
    if (confirmation) {
      await deletePastSearch(object);
      if (mySearches.length > 0) {
        setMySearches(mySearches.filter((item) => item?._id != object?._id));
      }
    }
  };

  return (
    <div style={{ margin: "10px" }}>
      {isProSearch && (
        <RewardDeductionFunction
          increment={customSearchNcCost}
          userData={userData}
          type="deduction"
          continueFunction={goToResultPage}
        />
      )}
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{ marginRight: "20px", display: "flex", alignItems: "center" }}
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBack size={30} />
        </div>
        <h2>Search</h2>
      </div>
      <div
        style={{ marginTop: "20px", marginBottom: "80px" }}
        className="groupsCreateFormSection"
      >
        <h3>Search by Name</h3>
        <div className="bioContainer" style={{ marginTop: "20px" }}>
          <input
            type="text"
            id="name"
            className="bioInput"
            maxLength={60}
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <div className="bioLengthContainer">{name?.length}/60</div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            alignContent: "center",
            width: "70%",
            marginTop: "10px",
          }}
          onClick={() => setProFiltersOpened(!isProFiltersOpened)}
        >
          <h3 style={{ margin: "-4px 20px 0px 0px" }}>Pro Filters</h3>

          {isProFiltersOpened ? (
            <MdToggleOn size={30} />
          ) : (
            <MdOutlineToggleOff size={30} />
          )}
        </div>
        <div>
          <p style={{ fontSize: "15px", color: "#aaaaaa", marginTop: "8px" }}>
            These are Pro filters. If you use them without having a Pro account,
            you will be asked to use your NetCoin.
          </p>
        </div>
        {isProFiltersOpened && (
          <div>
            <div style={{ marginTop: "20px" }}>
              <div className="groupsCreateFormSection">
                <h4> Business Experience</h4>
                <Select
                  mode="multiple"
                  className="bioInput"
                  style={{ marginTop: "2%", padding: "3px", flexWrap: "wrap" }}
                  maxTagCount={2}
                  variant="borderless"
                  placeholder="Select business types"
                  value={selectedBusinessExp}
                  onChange={setSelectedBusinessExp}
                  required
                >
                  {businesses.map((option) => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="groupsCreateFormSection">
                <h4> Business Interests</h4>
                <Select
                  mode="multiple"
                  className="bioInput"
                  style={{ marginTop: "2%", padding: "3px", flexWrap: "wrap" }}
                  maxTagCount={2}
                  variant="borderless"
                  placeholder="Select business types"
                  value={selectedBusinessInterest}
                  onChange={setSelectedBusinessInterest}
                  required
                >
                  {businesses.map((option) => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="groupsCreateFormSection">
                <h4> Skill </h4>
                <Select
                  mode="multiple"
                  className="bioInput"
                  style={{ marginTop: "2%", padding: "3px", flexWrap: "wrap" }}
                  maxTagCount={2}
                  variant="borderless"
                  placeholder="Select preferred skills categories"
                  value={selectedSkills}
                  onChange={setSelectedSkills}
                  required
                >
                  {skills.map((option) => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="groupsCreateFormSection">
                <h4> Hobbies</h4>
                <Select
                  mode="multiple"
                  className="bioInput"
                  style={{ marginTop: "2%", padding: "3px", flexWrap: "wrap" }}
                  maxTagCount={2}
                  variant="borderless"
                  placeholder="Select preferred hobbies categories"
                  value={selectedHobbies}
                  onChange={setSelectedHobbies}
                  required
                >
                  {hobbies.map((option) => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        )}
        {!isLoading && isEmptySearch && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "-15px",
            }}
          >
            <p style={{ fontWeight: "bold", fontSize: "15px" }}>
              You Got 0 results. Try again
            </p>
          </div>
        )}
        <div
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            width: "100%",
            marginTop: "30px",
          }}
        >
          {!isLoading && !areThereResults && (
            <Button
              type="primary"
              style={{ backgroundColor: "#f8a64b", fontWeight: "bold" }}
              onClick={handleSearch}
            >
              Search
            </Button>
          )}
          {isLoading && (
            <Button
              type="primary"
              loading
              style={{ backgroundColor: "#f8a64b", fontWeight: "bold" }}
            >
              Loading
            </Button>
          )}
          {!isLoading && areThereResults > 0 && (
            <Button
              type="primary"
              style={{ backgroundColor: "#f8a64b", fontWeight: "bold" }}
              onClick={showSearchResults}
            >
              Get {areThereResults > 30 ? `30+ ` : `${areThereResults} `}
              results
            </Button>
          )}
        </div>
        <div>
          <hr style={{ width: "99%", marginTop: "20px" }} />
          <h3 style={{ margin: "20px 0px " }}>My Pro Searches</h3>
          {mySearches.length > 0 &&
            mySearches.map((item, index) => (
              <div className="section matchingSection">
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "83vw",
                    }}
                  >
                    <h3 style={{ felx: "3", margin: "0px 0px 8px" }}>
                      Search {index + 1}
                    </h3>
                    <div onClick={() => handleDeletePastSearch(item)}>
                      <MdDeleteOutline size={25} />
                    </div>
                  </div>
                  <div onClick={() => handlePastSearch(item)}>
                    {item.businessExperience.length > 0 && (
                      <div style={{ maxWidth: "100%" }}>
                        <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                          Business Experience:
                        </p>
                        <p style={{ margin: "0px 5px" }}>
                          {item.businessExperience.join(", ")}
                        </p>
                      </div>
                    )}
                    {item.businessInterests.length > 0 && (
                      <div>
                        <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                          Business Interests:
                        </p>
                        <p style={{ margin: "0px 5px" }}>
                          {item.businessInterests.join(", ")}
                        </p>
                      </div>
                    )}
                    {item.skills.length > 0 && (
                      <div>
                        <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                          Skills:{" "}
                        </p>
                        <p style={{ margin: "0px 5px" }}>
                          {item.skills.join(", ")}
                        </p>
                      </div>
                    )}
                    {item.hobbies.length > 0 && (
                      <div>
                        <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                          Hobbies:{" "}
                        </p>
                        <p style={{ margin: "0px 5px" }}>
                          {item.hobbies.join(", ")}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {pastSearchIsLoading == item._id ? (
                      <Spin />
                    ) : (
                      <MdArrowForward size={30} />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
