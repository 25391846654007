import React, { useState } from "react";
import { AutoComplete } from "antd";
import axios from "axios";

const CitySelector = ({
  initialValue,
  functionCityStateVar,
  functionPositionStateVar,
  style
}) => {
  const [searchQuery, setSearchQuery] = useState(initialValue);
  const [options, setOptions] = useState([]);

  const getLocationCoordinatesAndName = async (inputCity) => {
    try {
      // Effettua la chiamata a Nominatim per ottenere i dati sulla città
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/search?format=jsonv2&addressdetails=1&q=${inputCity}`
      );

      const cities = response.data.map((cityData) => {
        const { lat, lon, name, address, place_id } = cityData;
        const { country_code, county } = address;
        const dettaglio =
          county !== undefined && county !== name ? county + ", " : "";
        const displayName = `${name}, ${dettaglio}${country_code.toUpperCase()}`;
        return {
          latitude: lat,
          longitude: lon,
          value: displayName,
          label: displayName,
          name: name,
          county: county,
          country_code: country_code.toUpperCase(),
          key: place_id,
        };
      });

      return cities;
    } catch (error) {
      console.error("Errore durante la ricerca della città:", error);
      return [];
    }
  };

  const handleInputChange = async (value) => {
    setSearchQuery(value);
    if (value) {
      // Effettua la chiamata per ottenere i dati sulla città
      const cityData = await getLocationCoordinatesAndName(value);

      if (cityData) {
        // Rimuovi duplicati dalle opzioni
        const uniqueOptions = cityData.filter(
          (element, index) => cityData.indexOf(element) === index
        );
        setOptions(uniqueOptions);
      } else {
        setOptions([]); // Azzera le opzioni se non sono disponibili dati sulla città
      }
    }
  };

  const handleSelect = (selectedValue, option) => {
    setSearchQuery(selectedValue);
    functionCityStateVar(selectedValue);
    if (functionPositionStateVar) {
      functionPositionStateVar({
        latitude: option.latitude,
        longitude: option.longitude,
      });
      setOptions([]);
    }
  };

  return (
    <AutoComplete
      className={style}
      options={options}
      variant="borderless"
      placeholder="Digita il nome della città o il CAP"
      value={searchQuery}
      onChange={handleInputChange}
      onSelect={handleSelect} // Imposta la funzione di gestione per l'evento onSelect
    />
  );
};

export default CitySelector;
