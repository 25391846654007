import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { fecthNotification } from "../../../global-context/Slice";
import { useNavigate } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";

export default function Notifications() {
  const newNotifications = useSelector(
    (state) => state.fecthNotificationReducer.newNotifications
  );
  const readNotifications = useSelector(
    (state) => state.fecthNotificationReducer.readNotifications
  );
  const notifications = [...newNotifications, ...readNotifications];
  const userLogInformations = useSelector(
    (state) => state.authReducer.userInfo
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const myRef = useRef(false);
  const newNotificationsRef = useRef(newNotifications);
  const readNotificationsRef = useRef(readNotifications);

  useEffect(() => {
    const updateStateAndDb = () => {
      dispatch(
        fecthNotification({
          readNotifications: notifications,
          newNotifications: [],
          method: "overwrite",
        })
      );
      const response = axios.post(
        `${process.env.REACT_APP_NODEJS_URL}/setNotificationsAsRead`,
        { collection_id: userLogInformations.id }
      );
    };

    if (!myRef.current) {
      updateStateAndDb();
      myRef.current = true;
    }
  }, []);

  const goToChatRoom = () => {
    navigate("/chats");
  };

  const goToGroupDetailPage = (item) => {
    navigate(`/match/group-detail/${item.groupInfo.id}`, {
      state: { ...item.groupInfo, senderId: item.senderId },
    });
  };

  const goToNotificationSource = (item) => {
    if (item.type === "chat") {
      goToChatRoom();
    } else if (item.type === "groupInvitation") {
      goToGroupDetailPage(item);
    }
  };

  const NotificationsIterator = ({ notificationsToIterate }) => {
    return notificationsToIterate.map((item, index) => (
      <div
        key={index}
        onClick={() => {
          goToNotificationSource(item);
        }}
      >
        <div className="notificationContainer">
          {item.message}
          <div className="notificationTime">{`${item.date} ${item.time}`}</div>
        </div>
        <hr />
      </div>
    ));
  };

  return (
    <div style={{ margin: "10px" }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{ marginRight: "20px", display: "flex", alignItems: "center" }}
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBack size={30} />
        </div>
        <h2>Notifications</h2>
      </div>
      {newNotificationsRef.current.length > 0 ? (
        <div>
          <div className="notificationViewer" style={{ fontWeight: "bold" }}>
            <h3>Newest</h3>
            <NotificationsIterator
              notificationsToIterate={newNotificationsRef.current}
            />
          </div>
          <div className="notificationViewer">
            <h3>Previous</h3>
            <NotificationsIterator
              notificationsToIterate={readNotificationsRef.current}
            />
          </div>
        </div>
      ) : (
        <div className="notificationViewer">
          <NotificationsIterator
            notificationsToIterate={readNotificationsRef.current}
          />
        </div>
      )}
    </div>
  );
}
