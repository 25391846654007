import React, { useEffect, useRef, useState } from "react";
import "../../../../App.css";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { updateUserGroups } from "../../../global-context/Slice";

import { useSocket } from "../../../global-context/SocketContext";
import { Button } from "antd";
import { MdArrowBack } from "react-icons/md";
import { useParams } from "react-router-dom";

export default function GroupInvitation() {
  const socket = useRef(useSocket());
  const dispatch = useDispatch();
  const { receiverUserId } = useParams();

  const currentGroups = useSelector(
    (state) => state.fetchUsersGroupAndTeamsReducer.groups
  );

  const availableGroups = currentGroups.filter(
    (group) =>
      group.numberOfmembers < group.maxParticipants &&
      group.type === "IS_CREATOR"
  );

  const userData = useSelector((state) => state.fecthUserDataReducer);
  const userId = userData.id;

  const sendInvitation = async (groupInfo) => {
    const objectToSend = {
      senderInfo: {
        firstName: userData.firstName,
        lastName: userData.lastName,
        id: userId,
      },
      receiverUserId: receiverUserId,
      groupInfo: groupInfo,
    };
    socket.current.emit("join_group_invitation", objectToSend);
  };

  const updateNeo4jrelation = async (objectToSend) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_NODEJS_URL}/sendGroupInvitation`,
        objectToSend
      );
    } catch (e) {
      console.log(e);
    }
  };

  const handleGroupInvitation = async (groupInfo) => {
    sendInvitation(groupInfo);
    dispatch(
      updateUserGroups({
        groupId: groupInfo.id,
        invitedUserId: receiverUserId,
      })
    );
    updateNeo4jrelation({
      id: userId,
      groupId: groupInfo.id,
      invitedUserId: receiverUserId,
    });
  };

  return (
    <div style={{ margin: "10px" }}>
      <div
        style={{ marginRight: "20px", display: "flex", alignItems: "center" }}
        onClick={() => {
          window.history.back();
        }}
      >
        <MdArrowBack size={30} />
      </div>
      <div>
        <h2>Available groups!</h2>

        {availableGroups.length > 0 ? (
          <div>
            <p>Choose the group you want to invite the user to</p>
            <div style={{ marginTop: "20px" }}>
              {availableGroups.map((item, index) => (
                <div className="groupInvitationGroupBox">
                  <div>
                    <h5>{item.title}</h5>
                    <p>{item.description}</p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "10px",
                      }}
                    >
                      {!item.invitedUsers.includes(receiverUserId) ? (
                        <div>
                          <Button
                            className="groupinvitationButton"
                            onClick={() => {
                              handleGroupInvitation(item);
                            }}
                          >
                            Send
                          </Button>
                        </div>
                      ) : (
                        <div className="groupInvitationSent">
                          <p>Invitation sent</p>
                        </div>
                      )}
                      <p
                        style={{
                          alignSelf: "center",
                          fontSize: "15px",
                          fontWeight: "bold",
                          marginLeft: "auto",
                        }}
                      >
                        {item.memberIds
                          ? item.memberIds.length
                          : item.numberOfmembers}
                        /{item.maxParticipants}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div>
            <p>You haven't available groups </p>
          </div>
        )}
      </div>
    </div>
  );
}
