import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import CustomClipperLoader from "../../../hooks/CustomClipperLoader";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  MdAdd,
  MdArrowDropDown,
  MdInsertComment,
  MdThumbUp,
} from "react-icons/md";
import { Icons } from "react-toastify";
import { Button } from "antd";

export default function Feed() {
  const userDataId = useSelector((state) => state.fecthUserDataReducer.id);
  const navigate = useNavigate();
  const location = useLocation();
  const [likedPosts, updateLikedPosts] = useState(new Set());
  const [sessionNewLikes, updateNewLikes] = useState(new Set());
  const [commentedPosts, updateCommentedPosts] = useState(new Set());
  const [posts, setPosts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const fetchRef = useRef(false);

  const loadPosts = useCallback(async () => {
    if (!hasMore) return;
    if (!fetchRef.current) {
      fetchRef.current = true;
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_NODEJS_URL}/fetchPosts`,
          { userId: userDataId, page: page, limit: 3 }
        );
        const results = Object.values(response.data);

        setPosts((prevPosts) => [...prevPosts, ...results]);
        const newLikedPosts = new Set(
          results
            .map((item) => (item.isLikedByUser ? item.id : undefined))
            .filter((id) => id !== undefined)
        );
        updateLikedPosts(
          (prevLikedPosts) => new Set([...prevLikedPosts, ...newLikedPosts])
        );
        setHasMore(results.length > 0);
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch posts", error);
      }
    }
  }, [page]);

  useEffect(() => {
    loadPosts();
  }, [page, loadPosts]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight &&
      hasMore
    ) {
      fetchRef.current = false;
      setPage((prevPage) => prevPage + 1);
    }
  }, [hasMore]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const getTimeDiff = (timestamp) => {
    const now = Date.now();
    const diffInMilliseconds = Math.abs(now - timestamp); // Differenza in millisecondi
    const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60)); // Conversione in ore
    const diffInMin = Math.floor(diffInMilliseconds / (1000 * 60));
    if (diffInHours === 0 && diffInMin > 0) {
      return `${diffInMin} min`;
    } else if (diffInHours === 0 && diffInMin === 0) {
      return "now";
    } else if (diffInHours < 24) {
      return `${diffInHours} h`;
    } else {
      return `${Math.floor(diffInHours / 24)} d`;
    }
  };

  const handleLike = (item) => {
    updateLikedPosts((prevLikedPosts) => {
      const newLikedPosts = new Set(prevLikedPosts);
      if (newLikedPosts.has(item.id)) {
        newLikedPosts.delete(item.id);
      } else {
        newLikedPosts.add(item.id);
      }

      return newLikedPosts;
    });

    updateNewLikes((prevLikedPosts) => {
      const newLikedPosts = new Set(prevLikedPosts);
      if (newLikedPosts.has(item.id)) {
        newLikedPosts.delete(item.id);
      } else {
        newLikedPosts.add(item.id);
      }

      return newLikedPosts;
    });
    axios.post(`${process.env.REACT_APP_NODEJS_URL}/likePost`, {
      userId: userDataId,
      postId: item.id,
    });
  };

  const SinglePostAspect = (item) => {
    let likesNumber = likedPosts.has(item.id)
      ? sessionNewLikes.has(item.id)
        ? parseInt(item.likesNumber) + 1
        : parseInt(item.likesNumber)
      : parseInt(item.likesNumber) > 0
      ? parseInt(item.likesNumber) - 1
      : 0;
    const isCommented = commentedPosts.has(item.id);
    return (
      <div>
        <div style={{ margin: "10px 10px 0px" }}>
          <div
            className="postheader"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div className="postCreatorImg">
              <img
                src={item.profileImgLink}
                style={{
                  display: "block",
                  height: "40px",
                  borderRadius: "40px",
                  margin: "5px",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  className="postCreatorName"
                  style={{ fontWeight: "bold", fontSize: "15px" }}
                >
                  {`${item.firstName} ${item.lastName}`}
                </div>
                <div
                  style={{
                    margin: "0px 0px 0px 8px",
                    fontSize: "13px",
                    color: "#999999",
                  }}
                >
                  {getTimeDiff(item.timestamp)}
                </div>
              </div>
              {item.postTags && (
                <div
                  style={{
                    width: "fit-content",
                    display: "inline-block",
                    fontSize: "12px",
                    padding: "3px",
                    borderRadius: "10px",
                    color: "white",
                    backgroundColor: "#999999",
                  }}
                >
                  {" "}
                  <div> {item.postTags}</div>
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                marginLeft: "auto",
                marginRight: "5px",
              }}
            >
              <MdArrowDropDown />
            </div>
          </div>
          <div
            className="postMain"
            style={{ minHeight: "15vmax", padding: "10px" }}
          >
            <div
              style={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 5, // Limita il numero di righe
                overflow: "hidden",
                maxHeight: "30vmax",
              }}
            >
              {item.text}
            </div>
            <div>
              {item.mediaLink && (
                <img
                  style={{
                    display: "block",
                    width: "100%",
                    marginTop: "15px",
                    borderRadius: "10px",
                  }}
                  src={item.mediaLink}
                />
              )}
            </div>
          </div>
          <hr
            style={{
              width: "100%",
              marginTop: "10px",
              border: "none",
              height: "1px",
              backgroundColor: "#cccccc",
            }}
          />
          <div
            className="postFooter"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin: "8px 10px",
            }}
          >
            <div
              className="postLike"
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: "20%",
                height: "35px",
              }}
            >
              <MdThumbUp
                size={20}
                color={likedPosts.has(item.id) ? "red" : "black"}
                onClick={() => {
                  handleLike(item);
                }}
              />
              {parseInt(likesNumber) != 0 && (
                <div
                  style={{
                    marginRight: "auto",
                    marginLeft: "auto",
                    fontSize: "12px",
                    fontWeight:'bold'
                  }}
                >
                  {parseInt(likesNumber)}
                </div>
              )}
            </div>
            <div
              className="postComment"
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: "20%",
                height: "35px",
              }}
            >
              <Link to = {`post/${item.id}`}>
                <MdInsertComment size={20} />
              </Link>
              {parseInt(item.commentsNumber) + (isCommented ? 1 : 0) != 0 && (
                <div
                  style={{
                    marginRight: "auto",
                    marginLeft: "auto",
                    fontSize: "12px",
                    fontWeight:'bold'
                  }}
                >
                  {parseInt(item.commentsNumber) + (isCommented ? 1 : 0)}
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          style={{ height: "3px", width: "wmax", backgroundColor: "#dddddd" }}
        />
      </div>
    );
  };

  if (isLoading === true) {
    return <CustomClipperLoader />;
  }

  if (location.pathname === "/feed/create") {
    return <Outlet />;
  }

  if (location.pathname.includes('/feed/post')) {
    return <Outlet />;
  }
  

  return (
    <div>
      <Link to="/feed/create">
        <div>
          <MdAdd
            className="matchSymbolContainerSmall "
            color={"white"}
            size={25}
            style={{
              height: "auto",
              width: "auto",
              backgroundColor: "#f8a64b",
            }}
          />
        </div>
      </Link>
      {posts && posts.map((post) => SinglePostAspect(post))}
    </div>
  );
}
