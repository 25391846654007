import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // Importa useNavigate e useLocation
import "../../../../App.css";
import axios from "axios";
import { MdArrowBack, MdGroup } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import MatchingPeopleIterator from "./peopleResumeIterator";
import CustomClipperLoader from "../../../hooks/CustomClipperLoader";
import { useParams } from "react-router-dom";
import { Button } from "antd";
import { fetchUsersGroupAndTeams } from "../../../global-context/Slice";

const GroupView = () => {
  const [groupDetails, setGroupDetails] = useState(null);
  const { groupId } = useParams();
  const userData = useSelector((state) => state.fecthUserDataReducer);
  const currentGroups = useSelector(
    (state) => state.fetchUsersGroupAndTeamsReducer.groups
  );
  const [isMember, setIsMember] = useState(false);
  const navigate = useNavigate(); // Utilizza useNavigate invece di useHistory
  const dispatch = useDispatch();
  const location = useLocation(); // Utilizza useLocation

  const myRef = useRef(false);

  useEffect(() => {
    const getCommonFeatures = (groupObject) => {
      const { members } = groupObject;
      const updatedMembers = members.map((member) => ({
        ...member,
        different_skills: member.skills.filter(
          (skill) => !userData.skills.includes(skill)
        ),
        businessExperience: member.experience,
        common_business_interests: member.businessInterests.filter((interest) =>
          userData.businessInterests.includes(interest)
        ),
        common_hobbies: member.hobbies.filter((hobbie) =>
          userData.hobbies.includes(hobbie)
        ),
      }));
      const updatedGroupObject = { ...groupObject, members: updatedMembers };
      setGroupDetails(updatedGroupObject);
    };

    const fetchMembersData = async () => {
      if (!myRef.current) {
        const response = await axios.post(
          `${process.env.REACT_APP_NODEJS_URL}/getGroupDetail`,
          { id: groupId }
        );
        const groupObject = response.data;
        if (groupObject) {
          getCommonFeatures(groupObject);
        } else {
          console.log("qualcosa è andato storto:\n", groupObject);
          alert("errore");
        }
      }
    };

    fetchMembersData();
    myRef.current = true;
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goToGroupsPage = () => {
    navigate(`/match/groups`);
  };

  const enterToGroup = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_NODEJS_URL}/partecipateToGroup`,
      { userId: userData.id, groupId: groupId }
    );
    if (response.status === 201) {
      const memberIds = groupDetails.members.map((member) => member.id);
      const groupObject = {
        ...groupDetails,
        memberIds: memberIds,
        type: "IS_MEMBER",
      };
      dispatch(
        fetchUsersGroupAndTeams({
          groups: [...currentGroups, groupObject],
        })
      );
      setIsMember(true);
      goToGroupsPage();
    }
  };

  return (
    <div style={{ margin: "10px" }}>
      {groupDetails ? (
        <div className="">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                marginRight: "20px",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                window.history.back();
              }}
            >
              <MdArrowBack size={30} />
            </div>
            <h2>Group Details</h2>
          </div>
          <div style={{ margin: "20px 0 20px 0" }}>
            <h3 style={{ margin: "10px 0 10px 0 " }}>{groupDetails?.title}</h3>
            <p>{groupDetails?.description}</p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "40px",
              }}
            >
              {currentGroups.length < 3 ? (
                <Button
                  onClick={() => enterToGroup()}
                  type="primary"
                  className="form_button"
                  style={{ width: "150px", fontWeight: "bold" }}
                >
                  {isMember ? "You are a member" : "Became a member!"}
                </Button>
              ) : (
                <div className="groupsCreateNew">
                  <p>You reached the max number of groups</p>
                </div>
              )}
              <div
                className="groupsMembersNumber"
                style={{ alignSelf: "center", height: "20px" }}
              >
                <MdGroup size={20} />
                <p
                  style={{
                    alignSelf: "center",
                    fontSize: "15px",
                    fontWeight: "bold",
                    marginLeft: "3px",
                  }}
                >
                  {groupDetails.members.length}/{groupDetails.maxParticipants}
                </p>
              </div>
            </div>
            <hr style={{ margin: "30px 0 30px 0 " }} />

            <div>
              <h3 style={{ margin: "20px 0 20px 0" }}>Members:</h3>
              <MatchingPeopleIterator
                matchingPeople={groupDetails.members}
                userDataId={userData.id}
                navigate={navigate}
                buttonsEnabled={true}
              />
            </div>
          </div>
        </div>
      ) : (
        <CustomClipperLoader />
      )}
    </div>
  );
};

export default GroupView;
