import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import CustomClipperLoader from "../hooks/CustomClipperLoader";
import {
  fetchUserData,
  fecthCategories,
  fetchUsersGroupAndTeams,
  fecthNotification,
  fetchUsersMatched,
  initializeChatArray,
} from "../global-context/Slice";

export default function FetchData() {
  const [userData, setUserData] = useState();
  const dispatch = useDispatch();
  const userLogInformations = useSelector(
    (state) => state.authReducer.userInfo
  );
  const myRef = useRef(false);

  const getMatching = async ({ userData }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_MATCHING_API_URL,
        JSON.stringify(userData), // payload dei dati
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const sorted_matching_users = response.data.people.sort(
        (a, b) => b.total_score - a.total_score
      );
      let sorted_matching_groups = [];
      if (response.data.groups.lenght > 0) {
        const sorted_matching_groups = response.data.groups.sort(
          (a, b) => b.total_score - a.total_score
        );
      }

      const date = new Date();
      dispatch(
        fetchUsersMatched({
          lastUpdateTs: `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`,
          users: sorted_matching_users,
          groups: sorted_matching_groups,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getUserData = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_NODEJS_URL}/getUserInfo`,

      { ids: [userLogInformations.id] }
    );
    const userData = response.data[0].user;
    if (userData) {
      // console.log("fecthData: ", userData);
      setUserData(userData);
      dispatch(
        fetchUserData({
          id: userData.id,
          firstName: userData.firstName,
          lastName: userData.lastName,
          city: userData.city,
          netCoins: userData.netCoins ? userData.netCoins : 0,
          position: userData.position ? userData.position : null,
          hobbies: userData.hobbies ? userData.hobbies : null,
          skills: userData.skills ? userData.skills : null,
          businessInterests: userData.businessInterests
            ? userData.businessInterests
            : null,
          businessExperience: userData.businessExperience
            ? userData.businessExperience
            : null,
          imgProfileLocation: userData.imgProfileLocation
            ? userData.imgProfileLocation
            : null,
          bio: userData.bio ? userData.bio : null,
          isProfileCompleted: userData.isProfileCompleted
            ? userData.isProfileCompleted
            : null,
        })
      );

      await getMatching({ userData: userData });
    }
  };

  const getUserGroupsTeamsData = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_NODEJS_URL}/getUserGroupsTeamsInfo`,
      { id: userLogInformations.id }
    );
    const groupsTeamsData = response.data;

    if (groupsTeamsData) {
      // console.log("fecthData: ", userData);
      dispatch(
        fetchUsersGroupAndTeams({
          groups: groupsTeamsData.groups,
          teams: groupsTeamsData.teams,
        })
      );
    }
  };

  const getCategories = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_NODEJS_URL}/categories`
    );
    const categories = response.data;
    if (categories) {
      // console.log("fecthData Categories: ", categories);
      dispatch(
        fecthCategories({
          skills: categories.skills,
          businessTypes: categories.businessTypes,
          hobbies: categories.hobbies,
        })
      );
    }
  };

  const getNotifications = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_NODEJS_URL}/getNotifications`,
      { collection_id: userLogInformations.id }
    );
    const sortByDateDescending = (notifications) => {
      return notifications.sort((a, b) => new Date(b.date) - new Date(a.date));
    };

    const notifications = response.data;
    const newNotifications = sortByDateDescending(
      notifications.filter((notification) => !notification.read)
    );
    const readNotifications = sortByDateDescending(
      notifications.filter((notification) => notification.read)
    );

    if (notifications) {
      dispatch(
        fecthNotification({
          readNotifications: readNotifications,
          newNotifications: newNotifications,
          method: "overwrite",
        })
      );
    }
  };

  const getChats = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_NODEJS_URL}/getChats`,
      { collection_id: userLogInformations.id }
    );
    const chats = response.data;

    if (chats) {
      dispatch(
        initializeChatArray({
          chats: chats,
        })
      );
    }
  };

  useEffect(() => {
    if (!myRef.current) {
      getUserData();
      getCategories();
      getUserGroupsTeamsData();
      getNotifications();
      getChats();
      myRef.current = true;
    }
  }, []);

  return userData ? (
    <Outlet />
  ) : (
    <div>
      <div className="header">
        <img
          className="header_logo"
          alt="Doinit Logo"
          src={require("../../assets/logo.png")}
        />
      </div>
      <CustomClipperLoader />
    </div>
  );
}
