import React, { useState, useRef, useEffect } from "react";
import "../../../App.css";
import { MdSend, MdArrowBack } from "react-icons/md";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useSocket } from "../../global-context/SocketContext";
import axios from "axios";
import CustomClipperLoader from "../../hooks/CustomClipperLoader";
import RewardDeductionFunction from "../../hooks/NcRewardDeductionFunction";

export default function Chat() {
  const socket = useRef(useSocket());
  const { roomId } = useParams();
  const loadedHistory = useRef(false);
  const joinedTheRoom = useRef(false);
  const [wroteMessageText, setWroteMessageText] = useState("");
  const [messages, setMessages] = useState([]);
  const [groupMembersIds, setGroupMembersIds] = useState([]);
  const [isRewarded, setIsRewarded] = useState(false);

  const userData = useSelector((state) => state.fecthUserDataReducer);
  const messagesEndRef = useRef(null);
  const { state } = useLocation();
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (messages.length === 50 && state.chatType === "private") {
      setIsRewarded(true);
    }
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const loadChatHistory = async () => {
      if (!loadedHistory.current) {
        const response = await axios.post(
          `${process.env.REACT_APP_NODEJS_URL}/getChatHistory`,
          { roomId: roomId }
        );
        const pastMessages = response.data;
        setMessages(pastMessages);
      }
    };
    const getGroupMembers = async () => {
      if (!loadedHistory.current && state.chatType === "group") {
        const response = await axios.post(
          `${process.env.REACT_APP_NODEJS_URL}/getGroupMembers`,
          { id: state.id ? state.id : roomId }
        );
        const members = response.data.members;
        setGroupMembersIds(members.map((member) => member.id));
      }
    };
    loadChatHistory();
    getGroupMembers();
    loadedHistory.current = true;

    const object = {
      room: roomId,
      userId: userData.id,
    };

    if (!joinedTheRoom.current) {
      socket.current.emit("join_room", object);
      joinedTheRoom.current = true;
    }

    const receiveMessage = (data) => {
      setMessages((prevMessages) => [...prevMessages, data]);
    };

    socket.current.on("receive_message", receiveMessage);

    return () => {
      socket.current.off("receive_message", receiveMessage);
      //socket.current.emit("leave_room", object); // Disconnettersi dalla room
    };

    // return () => {

    //   socket.current.off("receive_message", receiveMessage);
    // };
  }, []);

  const padTime = (element) => {
    return element.toString().padStart(2, "0");
  };

  const createChat = async () => {
    const chatInfo = {
      name:
        state.chatType === "group"
          ? state.title
          : state.firstName + " " + state.lastName,
      id: state.id,
      roomId: roomId,
      updatedAt: new Date(),
      unreadMessages: 0,
      lastMessage: "",
      chatType: state.chatType,
      chatImg: state.chatImg,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_NODEJS_URL}/createChat`,
      { chatInfo: chatInfo, collection_id: userData.id }
    );
  };

  const sendMessage = () => {
    if (wroteMessageText != "") {
      const messageObj = {
        message: wroteMessageText,
        date: new Date(),
        user_id: userData.id,
        name:
          state.chatType === "private"
            ? `${userData.firstName} ${userData.lastName}`
            : state.title,
        otherInfo: {
          senderProfileImgUrl:
            state.chatType === "private"
              ? userData.imgProfileLocation
              : undefined,
          chatType: state.chatType,
        },
      };
      setMessages([...messages, messageObj]);
      setWroteMessageText("");
      socket.current.emit("chat", {
        message: messageObj,
        room: roomId,
        involvedUsersIds:
          state.chatType === "private" ? [state.id] : groupMembersIds,
      });
      createChat();
    }
  };

  return (
    <div className="mainChatView">
      {isRewarded && (
        <RewardDeductionFunction
          increment={30}
          goal={"You sent 50 messages to a user!"}
          userData={userData}
        />
      )}
      <div className="chatHeader">
        <div
          style={{ marginRight: "20px", display: "flex", alignItems: "center" }}
          onClick={() => {
            socket.current.emit("leave_room", { room: roomId });
            window.history.back();
          }}
        >
          <MdArrowBack size={30} />
        </div>
        <div>
          {state.chatType === "group"
            ? state.title
            : state.firstName + " " + state.lastName}
        </div>
      </div>
      <div className="messageViewer">
        {loadedHistory.current ? (
          messages.map((item, index) => (
            <div className="messageContainer" key={index}>
              <div
                className={
                  item.user_id === userData.id
                    ? "messageText right"
                    : "messageText"
                }
              >
                {item.user_id !== userData.id && (
                  <div className="senderName">{item.name}</div>
                )}
                {item.message}
                <div className="messageTime">{item.time}</div>
              </div>
            </div>
          ))
        ) : (
          <CustomClipperLoader />
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className="inputChatSection">
        <textarea
          type="text"
          id="mess"
          placeholder="Write your message..."
          className="messageTextArea"
          value={wroteMessageText}
          onChange={(e) => setWroteMessageText(e.target.value)}
          required
        />
        <div className="sendButton" onClick={sendMessage}>
          <MdSend size={20} />
        </div>
      </div>
      <div className="navBar" />
    </div>
  );
}
