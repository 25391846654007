import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { FaArrowLeft } from "react-icons/fa";
import "../../../../App.css";
import { Button, Select, AutoComplete } from "antd";
import axios from "axios";
import { fetchUsersGroupAndTeams } from "../../../global-context/Slice";

const { Option } = Select;

export default function CreateGroup() {
  const [maxParticipants, setMaxParticipants] = useState(2);
  const [businessSector, setBusinessSector] = useState([]);
  const [preferredSkills, setPreferredSkills] = useState([]);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [error, setError] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.fecthUserDataReducer);
  const categories = useSelector((state) => state.fecthCategoriesReducer);
  const groupsAndTeams = useSelector(
    (state) => state.fetchUsersGroupAndTeamsReducer
  );
  const groups = groupsAndTeams.groups;
  const businessTypes = categories.businessTypes
    .map((objetto) => objetto.items)
    .flat()
    .filter((option) => option !== "");
  const skillCategories = categories.skills
    .map((objetto) => objetto.category)
    .flat()
    .filter((option) => option !== "");

  const createNeo4jGroupNode = async (object) => {
    axios.post(`${process.env.REACT_APP_NODEJS_URL}/createUpdateGroup`, object);
  };

  const resetStateVariables = () => {
    setMaxParticipants("");
    setBusinessSector([]);
    setPreferredSkills([]);
    setDescription("");
  };

  const handleGroupPartecipantsInsert = (e) => {
    const value = parseInt(e.target.value, 10);
    if (isNaN(value)) {
      setError("insert the max number of partecipants");
      setMaxParticipants(value);
    } else if (value >= 2 && value <= 7) {
      setMaxParticipants(value);
      setError();
    } else {
      setError("The max number of the partecipants must be between 2 and 7");
      // if (value < 2) {
      //   setMaxParticipants(2)
      // } else if (value > 7) {
      //   setMaxParticipants(7)
      // }
      alert(
        "Attention: The number of the partecipants must be between 2 and 7"
      );
    }
  };

  const handleError = (message) => {
    const confirmation = window.confirm(message);
    if (confirmation) {
      setError(null)
    } else {
      setError(null)
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const today = new Date();
    const month = today.getMonth().toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");

    const groupCreationDate = `${today.getFullYear()}-${month}-${day}`;
    const groupId = `GRP-${userData.id}-${Date.now()}`;

    if (
      title != "" &&
      description != "" &&
      businessSector.length > 0 &&
      !isNaN(maxParticipants)
    ) {
      const groupObject = {
        creatorId: userData.id,
        id: groupId,
        title: title,
        description: description,
        creationDate: groupCreationDate,
        position: {
          longitude: userData.position.x,
          latitude: userData.position.y,
        },
        preferredSkillCategories: preferredSkills,
        maxParticipants: maxParticipants,
        businessSector: businessSector,
      };
      // const newDispatchGroups = groups.push(groupObject)
      createNeo4jGroupNode(groupObject);
      resetStateVariables();
      dispatch(
        fetchUsersGroupAndTeams({
          groups: [
            ...groups,
            { ...groupObject, type: "IS_CREATOR", numberOfmembers: "1" },
          ],
        })
      );
      navigate(-1);
    } else {
      handleError("All the fields must be filled");
    }
  };

  return (
    <div style={{ border: "1px solid #cccccc" }}>
      <div className=" backBar">
        <Link to="/match/groups" style={{ marginRight: "auto" }}>
          <FaArrowLeft />
        </Link>
        <h2 style={{ marginRight: "auto" }}> Create a group</h2>
      </div>
      <form onSubmit={handleSubmit} style={{ padding: "5%" }}>
        <div className="groupsCreateFormSection">
          <h3>Group Title</h3>
          <div className="bioContainer" style={{ marginBottom: "-2px" }}>
            <input
              type="text"
              id="title"
              className="bioInput"
              maxLength={60}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
            <div className="bioLengthContainer">{title.length}/60</div>
          </div>
        </div>
        <div className="groupsCreateFormSection">
          <h3>What's the group about?</h3>
          <div className="bioContainer" style={{ marginBottom: "-2px" }}>
            <input
              type="text"
              id="desc"
              className="bioInput"
              maxLength={255}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
            <div className="bioLengthContainer">{description.length}/255</div>
          </div>
        </div>
        <div className="groupsCreateFormSection">
          <h3>Business Type</h3>
          <AutoComplete
            className="bioInput"
            options={businessTypes.map((option) => ({
              value: option,
              label: option,
            }))}
            variant="borderless"
            placeholder="Select business types"
            value={businessSector}
            onChange={(value) => setBusinessSector(value)}
          />
        </div>
        {/* <div className="groupsCreateFormSection">
          <h3>Preferred Skill Categories</h3>
          <Select
            mode="multiple"
            className="bioInput"
            style={{ marginTop: "2%", padding: "3px", flexWrap: "wrap" }}
            maxTagCount={2}
            variant="borderless"
            placeholder="Select preferred skills categories"
            value={preferredSkills}
            onChange={setPreferredSkills}
            required
          >
            {skillCategories.map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        </div> */}
        <div className="groupsCreateFormSection">
          <h3>Members max number</h3>
          <input
            type="number"
            id="maxParticipants"
            className="bioInput"
            style={{ width: "20vw", marginTop: "2%" }}
            min={2}
            max={7}
            value={maxParticipants}
            onChange={handleGroupPartecipantsInsert}
            required
          />
        </div>

        {error ? (
          <div style={{ color: "red" }}>
            <p>{error}</p>
          </div>
        ) : (
          <Button
            type="submit"
            className="form_button"
            style={{ width: "40vw", marginTop: "10%", marginLeft: "auto" }}
            color={"#b10491"}
            size="large"
            onClick={handleSubmit}
            block={error}
          >
            Create Group
          </Button>
        )}
      </form>
    </div>
  );
}
