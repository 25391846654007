import { Spin } from "antd";
import React from "react";

export default function CustomClipperLoader() {
  const symbol = require("../../assets/Symbol.png");
  return (
    // <div className="clipLoaderContainer">
    //   <div className={`customClipLoader`}>
    //     <img
    //       src={symbol}
    //       className="matchSymbol"
    //       alt="matchSymbol"
    //       style={{ height: "100px", width: "100px" }}
    //     />
    //   </div>
    // </div>
    <Spin size="large" fullscreen={true} />
  );
}
