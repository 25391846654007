import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MdEdit, MdCheck } from "react-icons/md";
import "../../../../App.css";
import { fetchUserData } from "../../../global-context/Slice";
import axios from "axios";

export default function ItemsViewerPicker({
  sectionTitle,
  sectionSubTitle,
  nodeLabelTarget,
  relationLabelWithTarget,
  reduxStateVariableName,
  items,
  stateSetterFunction,
  stateVariable,
  setSelectedItems,
  selectedItems,
}) {
  const userData = useSelector((state) => state.fecthUserDataReducer);
  const categories = useSelector((state) => state.fecthCategoriesReducer);
  const dispatch = useDispatch();

  const sendUpdateToNeo4j = (object) => {
    axios.post(
      `${process.env.REACT_APP_NODEJS_URL}/createUpdateRelation`,
      object
    );
  };

  const updateSelectedItemsArray = (item, setSelectedItems, selectedItems) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(
        selectedItems.filter((selectedItem) => selectedItem !== item)
      );
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const updateSelectedItems = (selectedItems) => {
    let changes = { id: userData.id };
    changes[reduxStateVariableName] = selectedItems;
    dispatch(fetchUserData(changes));
    sendUpdateToNeo4j({
      ...changes,
      node_label: nodeLabelTarget,
      relation: relationLabelWithTarget,
      names: selectedItems,
    });
  };

  return (
    <div className="section">
      <div className="sectionHeader">
        <div>
          <h2>{sectionTitle}</h2>
          <p style={{fontSize:15, marginTop:'2%', color:'#999999'}}>{ sectionSubTitle}</p>
        </div>
        <div
          onClick={() => {
            if (stateVariable === true) {
              updateSelectedItems(selectedItems);
              stateSetterFunction(!stateVariable);
            } else {
              stateSetterFunction(!stateVariable);
            }
          }}
          style={{ cursor: "pointer" }}
        >
          {stateVariable ? <MdCheck /> : <MdEdit />}
        </div>
      </div>
      {stateVariable ? (
        items.map((macroObject, index) => (
          <div key={index}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {macroObject.category}
            </div>
            <div className="itemsSelectionContainer">
              {macroObject.items.map((item, index) => (
                <div
                  className="itemSelection"
                  key={index}
                  onClick={() =>
                    updateSelectedItemsArray(
                      item,
                      setSelectedItems,
                      selectedItems
                    )
                  }
                  style={{
                    borderStyle: "dashed",
                    backgroundColor: selectedItems.includes(item)
                      ? "#f8a64b"
                      : "#f9f9f9",
                    borderColor: selectedItems.includes(item)
                      ? "#ffffff"
                      : "#b10491",
                    cursor: "pointer",
                  }}
                >
                  <span
                    className="itemText"
                    style={{
                      color: selectedItems.includes(item)
                        ? "#ffffff"
                        : "#b10491",
                    }}
                  >
                    {item}
                  </span>
                </div>
              ))}
            </div>
          </div>
        ))
      ) : (
        <div className="itemsSelectionContainer">
          {selectedItems.map((item, index) => (
            <div className="itemSelection" key={index}>
              <span className="itemText">{item}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
