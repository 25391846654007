import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import "../../App.css";
import { Link, Outlet, useLocation } from "react-router-dom";
import {
  MdHome,
  MdAccountCircle,
  MdGroups2,
  MdNotifications,
  MdSettings,
  MdChat,
} from "react-icons/md";
import { useMediaQuery } from "react-responsive";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { useSocket } from "../global-context/SocketContext";
import { fecthNotification, fetchChatUpdates } from "../global-context/Slice";

import { useNavigate } from "react-router-dom";

export default function Main() {
  const socket = useRef(useSocket());
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  // const lastSegment = pathSegments[pathSegments.length - 1];
  const lastSegment = pathSegments[1];
  const [mainPageSelected, setMainPageSelected] = useState(lastSegment);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 768px)",
  });
  const notifications = useSelector(
    (state) => state.fecthNotificationReducer.newNotifications.length
  );
  const logo = require("../../assets/logo.png");

  const sumNewMessages = (messages) => {
    return messages.reduce((acc, curr) => acc + curr.unreadMessages, 0);
  };

  const newMessages = useSelector((state) => state.fetchChatReducer.chats);
  const newMessagesCount = sumNewMessages(newMessages);

  useEffect(() => {
    setMainPageSelected(lastSegment);
  }, [lastSegment]);

  useEffect(() => {
    const updateNotificationState = (data) => {
      dispatch(
        fecthNotification({
          newNotifications: [data],
          method: "append",
        })
      );
    };

    const displayNotify = (data) => {
      toast(data.message, {
        position: "top-center",
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      updateNotificationState(data);
    };

    const updateChatState = (data) => {
      dispatch(
        fetchChatUpdates({
          roomId: data.roomId,
          lastMessage: data.lastMessage,
          updatedAt: data.updatedAt,
        })
      );
    };

    socket.current.on("notifications", displayNotify);

    socket.current.on("newMessages", updateChatState);

    return () => {
      socket.current.off("notifications", displayNotify);
      socket.current.off("newMessages", updateChatState);
    };
  }, []);

  const NavBar = () => {
    return (
      <div className="navBar">
        {isDesktopOrLaptop && (
          <img
            src={logo}
            id="logo"
            alt="Logo"
            onClick={() => {
              setMainPageSelected("profile");
              navigate("/profile");
            }}
          />
        )}

        <div className="navIcon" onClick={() => setMainPageSelected("feed")}>
          <Link to="/feed">
            <MdHome
              size={30}
              color={mainPageSelected === "feed" ? "#b10491" : "#000000"}
            />
          </Link>
        </div>

        <div className="navIcon" onClick={() => setMainPageSelected("match")}>
          <Link to="/match/people">
            <MdGroups2
              size={30}
              color={mainPageSelected === "match" ? "#b10491" : "#000000"}
            />
          </Link>
        </div>
        <div className="navIcon" onClick={() => setMainPageSelected("profile")}>
          <Link to="/profile">
            <MdAccountCircle
              size={30}
              color={mainPageSelected === "profile" ? "#b10491" : "#000000"}
            />
          </Link>
        </div>
        {isDesktopOrLaptop ? (
          <div
            className="navIcon"
            onClick={() => setMainPageSelected("notifications")}
          >
            {notifications > 0 ? (
              <div className="notificationsCircle">{notifications}</div>
            ) : undefined}
            <Link to="/notifications">
              <MdNotifications
                size={30}
                color={
                  mainPageSelected === "notifications" ? "#b10491" : "#000000"
                }
              />
            </Link>
          </div>
        ) : null}

        {isDesktopOrLaptop ? (
          <div className="navIcon" onClick={() => setMainPageSelected("chats")}>
            <div>
              <div>
                {newMessagesCount > 0 ? (
                  <div className="notificationsCircle">{newMessagesCount}</div>
                ) : undefined}
              </div>
              <div style={{ zIndex: "0" }}>
                <Link to="/chats">
                  <MdChat
                    size={25}
                    color={mainPageSelected === "chats" ? "#b10491" : "#000000"}
                  />
                </Link>
              </div>
            </div>
          </div>
        ) : null}
        {isDesktopOrLaptop ? (
          <div
            className="navIcon"
            onClick={() => setMainPageSelected("settings")}
          >
            <div
            // style={{ marginLeft: "25px" }}
            >
              <Link to="/settings">
                <MdSettings
                  size={25}
                  color={
                    mainPageSelected === "settings" ? "#b10491" : "#000000"
                  }
                />
              </Link>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  const MobileUpperNavBar = () => {
    return (
      <div className="logoContainer">
        <img
          src={logo}
          id="logo"
          alt="Logo"
          onClick={() => {
            setMainPageSelected("feed");
            navigate("/feed");
          }}
        />
        <div className="notAndSettContainer">
          <div>
            <div>
              {newMessagesCount > 0 ? (
                <div className="notificationsCircle">{newMessagesCount}</div>
              ) : undefined}
            </div>
            <div style={{ zIndex: "0" }}>
              <Link to="/chats">
                <MdChat
                  size={25}
                  color={mainPageSelected === "chats" ? "#b10491" : "#000000"}
                />
              </Link>
            </div>
          </div>
          <div>
            <div>
              {notifications > 0 ? (
                <div className="notificationsCircle">{notifications}</div>
              ) : undefined}
            </div>

            <div style={{ zIndex: "0" }}>
              <Link to="/notifications">
                <MdNotifications
                  size={25}
                  color={
                    mainPageSelected === "notifications" ? "#b10491" : "#000000"
                  }
                />
              </Link>
            </div>
          </div>
          <div
          // style={{ marginLeft: "25px" }}
          >
            <Link to="/settings">
              <MdSettings
                size={25}
                color={mainPageSelected === "settings" ? "#b10491" : "#000000"}
              />
            </Link>
          </div>
        </div>
      </div>
    );
  };
  if (isDesktopOrLaptop) {
    return (
      <div>
        <NavBar />
        <ToastContainer
          position="top-right"
          autoClose={500}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          theme="light"
        />
        <Outlet />
      </div>
    );
  } else {
    return (
      <div>
        <MobileUpperNavBar />
        <Outlet />
        <ToastContainer
          position="top-center"
          autoClose={500}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          theme="light"
        />
        <NavBar />
      </div>
    );
  }
}
