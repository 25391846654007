import React from "react";
import "../../App.css";
import { Button } from "antd";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export const HomePage = () => {
  const logo = require("../../assets/logo.png");
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 768px)",
  });
  const navigate = useNavigate();
  const publicUrl = process.env.PUBLIC_URL;
  return (
    <div>
      <Helmet>
        <title>Doinit - Home Page</title>
        <meta
          name="description"
          content="Join our community of like-minded entrepreneurs and take your ambitions to the next level. Follow The Fear, Find Your Tribe and Keep Doing It!"
        />
        <meta
          name="keywords"
          content="entrepreneurs, business, community, networking"
        />
        <meta
          property="og:title"
          content="Home Page - Connect with Like-Minded Entrepreneurs"
        />
        <meta
          property="og:description"
          content="Join our community of like-minded entrepreneurs and take your ambitions to the next level. Follow The Fear, Find Your Tribe and Keep Doing It!"
        />
        <meta
          property="og:image"
          content={`${publicUrl}/logo512.png`}
        />
        <meta property="og:url" content={`${publicUrl}/HomePage`} />
      </Helmet>
      <div className="homePageHeader">
        <div className="homePageNavBar">
          <img
            src={logo}
            id="logo"
            alt="Logo"
            onClick={() => {
              navigate("/");
            }}
          />

          {/* {isDesktopOrLaptop && ( */}
          <div className="homePageHeaderButtons">
            <div>
              <Link to={"/login"}>
                <Button>Log in</Button>
              </Link>
            </div>
            <div>
              <Link to={"/register"}>
                <Button
                  style={{
                    backgroundColor: "#ff4d4f",
                    color: "white",
                    fontWeight: "bold",
                    marginRight: 0,
                  }}
                >
                  Sign in
                </Button>
              </Link>
            </div>
          </div>
          {/* )} */}
          {/* {!isDesktopOrLaptop && (
            <div className="homePageHeaderButtons">
              <Link>
                <MdMenu size={25} color="black"/>
              </Link>
            </div>
          )} */}
        </div>
      </div>
      <div className="homePageMain">
        <div className="homePageHeroSection">
          <h3>
            Connect with like-minded entrepreneurs, aspiring ones and business
            passionates in your area and take your ambitions to the next level.
          </h3>
          <p>Follow The Fear, Find Your Tribe and Keep Doing It!</p>
          <div className="homePageCTA homePageJoinButton">
            <Link to={"/register"} style={{ textDecoration: "none" }}>
              <Button>Join now</Button>
            </Link>
          </div>
        </div>

        <div className="homePageProblem homePageGeneralSection">
          <h1 style={{ marginBottom: "5px" }}>Why We Exist</h1>
          <p>
            Have you ever felt that spark of inspiration, only to be weighed
            down by feelings of isolation and doubt? You're not alone. We
            understand the challenges you're facing because we've been there
            too.
          </p>
          <p>
            We've created this MVP platform because we know, we've felt it deep
            in our guts, how limiting and painful it is not to feel understood
            and supported, especially when you're on the verge of making a big
            change and taking a leap.
          </p>
        </div>
        <div className="homePageSolution homePageGeneralSection">
          <h1 style={{ marginBottom: "5px" }}>What We Propose</h1>

          <div className="homePageListFeatures">
            <div className="homePageListItem">
              <h2>Easy Connections</h2>
              <p>
                An intuitive platform that connects you with like-minded
                individuals in your area.
              </p>
            </div>
            <div className="homePageListItem">
              <h2>Motivating Community</h2>
              <p>
                Join a community that supports and encourages your ambitions and
                earn coins to unlock pro features or to get in app discounts
              </p>
            </div>
            <div className="homePageListItem">
              <h2>Skill Matching</h2>
              <p>
                Find individuals with complementary skills and common passions
                to collaborate with.
              </p>
            </div>
          </div>
          <p>
            You're not just joining a network; you're becoming part of a
            community that genuinely cares about your journey.
          </p>
        </div>
        <div className="homePageHowItworks homePageGeneralSection">
          <h1 style={{ marginBottom: "5px" }}>How It Works</h1>
          <div className="homePageListFeatures">
            <div className="homePageListItem">
              <h2>Sign Up</h2>
              <p>
                Create your profile and tell us about your ambitions and
                interests.
              </p>
            </div>
            <div className="homePageListItem">
              <h2>Match</h2>
              <p>
                Discover and connect with like-minded individuals in your area
                with similar interests and passions and completary skills.
              </p>
            </div>
            <div className="homePageListItem">
              <h2>Contact</h2>
              <p>
                chat with matched people, Join discussion groups, create teams
                and built your future.
              </p>
            </div>
          </div>
        </div>
        <div className="homePageMVP homePageGeneralSection">
          <h1 style={{ marginBottom: "5px" }}>
            Exclusive Offer for Early Users
          </h1>
          <p>
            As a token of our appreciation for helping us build and improve this
            MVP platform, the first 500 users to register will receive pro
            privileges for all future pro features.
          </p>
        </div>
        <div className="  homePageCTAExtended">
          <h1></h1>
          <p>
            Together, we can turn those lonely nights into shared victories.
          </p>
          <div className="homePageCTA homePageJoinButton">
            <Link to={"/register"} style={{ textDecoration: "none" }}>
              <Button>Join now</Button>
            </Link>
          </div>
          <p>Let's make our dreams happen, side by side.</p>
        </div>
        <div className="homePageFooter">
          <Link
            style={{ color: "white", fontSize: "12px", margin: "10px" }}
            to={"/terms&Cond"}
          >
            <p>Terms and Conditions</p>
          </Link>
          <Link
            style={{ color: "white", fontSize: "12px", margin: "10px" }}
            to={"/whoWeAre"}
          >
            <p>Who we are</p>
          </Link>
          <Link
            style={{ color: "white", fontSize: "12px", margin: "10px" }}
            to={"/contacts"}
          >
            <p>Contacts</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
