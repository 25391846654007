import React, { useState } from "react";
import { MdLocationPin, MdAddCircle, MdMessage } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const getChatRoomId = (id1, id2) => {
  const sortedIds = [id1, id2].sort();

  // Concatena gli ID ordinati in un'unica stringa
  const combinedId = sortedIds[0] + sortedIds[1];
  return combinedId;
};

const goToChatPage = (item, userDataId, navigate) => {
  const room_id = getChatRoomId(item.id, userDataId);
  navigate(`/chat/${room_id}`, {
    state: { ...item, chatType: "private", room_id: room_id },
  });
};

const ReviewIterator = ({ label, items }) => {
  const slicedItems = items.slice(0, 10); // Estrai solo i primi tre elementi
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          fontSize: "13px",
          flexWrap: "wrap",
        }}
      >
        <p style={{ fontWeight: "bold", marginRight: "2%" }}> {label}:</p>
        {slicedItems.map((item, index) => (
          <div key={index}>
            <p style={{ marginRight: "2px" }}>
              {slicedItems?.length > 0 && index != slicedItems?.length - 1
                ? item + ","
                : item + ""}

              {index === 9 ? " ... " : ""}
            </p>{" "}
          </div>
        ))}
      </div>
      <hr style={{ margin: "1% 0 1% 0" }} />
    </div>
  );
};

const MatchingPeopleIterator = ({
  matchingPeople,
  userDataId,
  navigate,
  buttonsEnabled,
}) => {
  const groups = useSelector((state) => state.fetchUsersGroupAndTeamsReducer.groups);
  const createdGroups = groups.filter((group) => group.type === "IS_CREATOR");

  return (
    <div className="matchingPeopleMainSection">
      {matchingPeople.map((item, index) => (
        <div className="section matchingSection " key={index}>
          <div className="matchingScore">
            {/* <p
                style={{
                  fontSize: 22,
                  fontWeight: "bold",
                }}
              >
                {Math.round(item.total_score) + " %"}
              </p> */}
          </div>
          <div
            className=" matchingProfileImgSub"
            style={
              item.businessExperience_score > 0
                ? { border: "3px solid #eb5e32" }
                : {}
            }
          >
            <img
              src={item.imgProfileLocation}
              className=" matchingPeopleImg"
              alt="matchingPeopleImg"
            />
          </div>
          <div className="matchingPeopleInfo">
            <div className="matchingPeopleMainInfo">
              <h2 className="matchingNames">
                {item.firstName} {item.lastName}
              </h2>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                  width: "300px",
                  margin: "0 0 10px 5px",
                }}
              >
                <p
                  // className="secondaryInfo"
                  style={{ alignItems: "baseline", fontWeight: "bold" }}
                >
                  <MdLocationPin />
                  {item.city}
                </p>
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    color: "#999999",
                    marginLeft: "2%",
                  }}
                >
                  {Math.round(item.straight_line_distance)} Km
                </p>
              </div>
              {buttonsEnabled && (
                <div className="matchingPeopleButtons">
                  {createdGroups.length > 0 && (
                    <Link
                      to={`/match/groupInvitation/${item.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="matchingActionPeopleButton">
                        <MdAddCircle size={20} color="black" />
                      </div>
                    </Link>
                  )}

                  <div
                    className="matchingActionPeopleButton"
                    onClick={() => goToChatPage(item, userDataId, navigate)}
                  >
                    <MdMessage size={20} />
                  </div>
                </div>
              )}
            </div>
            <div className="matchingPeopleReview">
              <ReviewIterator label={" Bio"} items={[item.bio]} />
              {item?.common_business_interests.length > 0 && (
                <ReviewIterator
                  label={" Common Business Interests"}
                  items={item.common_business_interests}
                />
              )}
              {item?.businessExperience.length > 0 && (
                <ReviewIterator
                  label={" Entrepreneurial Experience fields"}
                  items={item.businessExperience}
                />
              )}
              {item?.different_skills.length > 0 && (
                <ReviewIterator
                  label={"Complementary Skills"}
                  items={item.different_skills}
                />
              )}
              {item?.common_hobbies.length > 0 && (
                <ReviewIterator
                  label={" Common Hobbies"}
                  items={item.common_hobbies}
                />
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MatchingPeopleIterator;
