import React, { useState, useEffect } from "react";
import "../../App.css";
import { Button, Divider, Input } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { logIn } from "../global-context/Slice";
import { useDispatch } from "react-redux";
import Monitoring from "../hooks/useMonitroing";
import CitySelector from "../hooks/CityPicker";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

export const UserRegistration = ({ Parse }) => {
  // State variables
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setCheckPassword] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [city, setCity] = useState(null);
  const [position, setPosition] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const logOut = async () => {
      const currentUser = await Parse.User.current();
      if (currentUser) {
        await Parse.User.logOut();
      }
    };
    logOut();
  }, []);

  const checkPassword = () => {
    if (password !== confirmPassword) {
      throw new Error("Errore! Le password non corrispondono");
    } else {
      return true;
    }
  };

  // const getLocationCoordinates = async (inputCity) => {
  //   const getResponse = await axios.get(
  //     `https://nominatim.openstreetmap.org/search?format=json&q=${inputCity}`
  //   );
  //   const { lat, lon } = getResponse.data[0];
  //   console.log(" lat, lon -> ", lat, lon);
  //   return { latitude: lat, longitude: lon };
  // };

  const defineNewParseUser = () => {
    if (checkPassword()) {
      const user = new Parse.User();
      user.set("username", username);
      user.set("email", username);
      user.set("password", password);
      return user;
    } else {
      return undefined;
    }
  };

  const parseSignUp = async function () {
    const user = defineNewParseUser();
    const createdUser = await user.signUp();
    return createdUser;
  };

  const createUserNodeObject = async () => {
    const parseUser = await parseSignUp();
    // const locationCoordinates = await getLocationCoordinates(city);

    const nodeUserObject = {
      id: parseUser.id,
      firstName: firstName,
      lastName: lastName,
      position: position,
      city: city,
    };
    return nodeUserObject;
  };

  const postUserNodeObject = async () => {
    const userData = await createUserNodeObject();
    const postResponse = await axios.post(
      `${process.env.REACT_APP_NODEJS_URL}/createUpdate`,
      userData
    );
  };

  const handleRegistration = async () => {
    try {
      await postUserNodeObject();
      const current = await Parse.User.current();
      const sessionToken = await current.getSessionToken();
      // Monitoring({
      //   id: current?.id,
      //   action: "SIGNUP",
      //   additionalInfo: {},
      // });
      dispatch(logIn({ userInfo: { ...current }, sessionToken: sessionToken }));
      navigate("/");
    } catch (error) {
      // const currentUser = await Parse.User.current();
      // if (currentUser) {
      //   await Parse.User.destroy();
      // }
      alert(`Error! ${error}`);
      return undefined;
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div style={{ margin: "10px" }}>
      <div className="header">
        <img
          className="header_logo"
          alt="Doinit Logo"
          src={require("../../assets/logo.png")}
        />
      </div>
      <div className="container">
        <h2 className="heading">{"User Registration"}</h2>
        <Divider />

        <div className="form_wrapper">
          <Input
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            placeholder="E-mail"
            size="large"
            className="form_input"
          />
          <Input
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            placeholder="Password"
            size="large"
            type={passwordVisible ? "text" : "password"}
            className="form_input"
            suffix={
              passwordVisible ? (
                <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
              ) : (
                <EyeOutlined onClick={togglePasswordVisibility} />
              )
            }
          />
          <Input
            value={confirmPassword}
            onChange={(event) => setCheckPassword(event.target.value)}
            placeholder="Confirm password"
            size="large"
            type={passwordVisible ? "text" : "password"}
            className="form_input"
            suffix={
              passwordVisible ? (
                <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
              ) : (
                <EyeOutlined onClick={togglePasswordVisibility} />
              )
            }
          />
          <Input
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
            placeholder="First Name"
            size="large"
            type="text"
            className="form_input"
          />
          <Input
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
            placeholder="Last Name"
            size="large"
            type="text"
            className="form_input"
          />
          <div style={{height:'70px'}}>
            <CitySelector
              initialValue={city}
              functionCityStateVar={setCity}
              functionPositionStateVar={setPosition}
              style={'registrationCityPicker'}
            />
          </div>
        </div>
        <div className="form_buttons">
          <Button
            onClick={() => handleRegistration()}
            type="primary"
            className="form_button"
            color={"#208AEC"}
            size="large"
          >
            Sign Up
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserRegistration;
