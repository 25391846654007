import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../../../../App.css";
import { MdLocationPin } from "react-icons/md";
import { fetchUserData } from "../../../global-context/Slice";
import axios from "axios";
import AWS from "aws-sdk";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import ItemsViewerPicker from "./UserPreferences.js";
import BioArea from "./UserBio.js";
import RewardDeductionFunction from "../../../hooks/NcRewardDeductionFunction.js";

export default function Profile() {
  const userData = useSelector((state) => state.fecthUserDataReducer);
  const groups = useSelector(
    (state) => state.fetchUsersGroupAndTeamsReducer.groups
  );
  const groupsCount = groups.length > 0 ? groups.length : 0;
  const categories = useSelector((state) => state.fecthCategoriesReducer);

  const [modifySkill, setModifySkill] = useState(false);
  const [modifyBusinessInterests, setModifyBusinessInterests] = useState(false);
  const [modifyBusinessExp, setModifyBusinessExp] = useState(false);
  const [modifyHobbies, setModifyHobbies] = useState(false);
  const [modifyBio, setModifyBio] = useState(false);
  const [modifyProfileImg, setModifyProfileImg] = useState(false);
  const [isExperienced, setIsExperienced] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState(userData.skills);
  const [selectedBusinessInterest, setSelectedBusinessInterest] = useState(
    userData.businessInterests
  );
  const [selectedBusinessExp, setSelectedBusinessExp] = useState(
    userData.businessExperience
  );
  const [selectedHobbies, setSelectedHobbies] = useState(userData.hobbies);
  const [selectedBio, setSelectedBio] = useState(userData.bio);
  const [selectedProfileImg, setSelectedProfileImg] = useState(null);
  const [croppedProfileImg, setCroppedProfileImg] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isRewarded, setIsRewarded] = useState(false);

  const profileImage = userData.imgProfileLocation
    ? userData.imgProfileLocation
    : require("../../../../assets/profilePictureDefault.png");

  const dispatch = useDispatch();

  useEffect(() => {
    if (userData.businessExperience.length > 0) {
      setIsExperienced(true);
    } else {
      setIsExperienced(false);
    }
  }, []);

  useEffect(() => {
    if (
      userData.isProfileCompleted == false &&
      userData.bio != null &&
      userData.businessInterests.length > 0 &&
      userData.skills.length > 0 &&
      userData.hobbies.length > 0 &&
      userData.imgProfileLocation != null
    ) {
      const updatePayload = { id: userData.id, isProfileCompleted: true };
      dispatch(fetchUserData(updatePayload));
      sendUpdateToNeo4j(updatePayload);
      setIsRewarded(true);
    }
  });

  const fileInputRef = useRef(null);
  const S3_BUCKET = "prflimgs";
  const REGION = "eu-central-1";

  const sendUpdateToNeo4j = (object) => {
    axios.post(`${process.env.REACT_APP_NODEJS_URL}/createUpdate`, object);
  };

  const handleUpload = async (croppedImg) => {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });
    
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const params = {
      Bucket: S3_BUCKET,
      Key: `${userData.id}/currentProfileImg.png`, //${selectedProfileImg.file.name}
      Body: croppedImg?.file ? croppedImg.file : selectedProfileImg.file,
    };

    s3.putObject(params, (err, data) => {
      if (err) {
        console.error(err);
        alert("Qualcosa è andato storto ritenta");
      } else {
        const uri = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${userData.id}/currentProfileImg.png`; //${selectedProfileImg.file.name}`;
        const objectToUpload = { id: userData.id, imgProfileLocation: uri };
        dispatch(fetchUserData(objectToUpload));
        sendUpdateToNeo4j(objectToUpload);
      }
    }).on("httpUploadProgress", (evt) => {
      setProgress(Math.round((evt.loaded / evt.total) * 100));
    });
  };

  const MetricDiv = ({ label, value }) => {
    return (
      <div className="metricContainer">
        <p>{label}:</p>
        <p>{value}</p>
      </div>
    );
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (event) => {
    setModifyProfileImg(true);
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setSelectedProfileImg({
        uri: URL.createObjectURL(selectedFile),
        file: selectedFile,
      });
    }
  };

  const Badge = () => {
    const [cropper, setCropper] = useState(null);

    const sendCrppedImage = async () => {
      if (cropper) {
        const file = await fetch(cropper.getCroppedCanvas().toDataURL())
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File([blob], "newProfileImage.png", {
              type: "image/png",
            });
            const fileToSend = {
              uri: URL.createObjectURL(file),
              file: file,
            };

            setCroppedProfileImg(fileToSend);
            return fileToSend;
          });
        return file;
      } else {
        return undefined;
      }
    };

    return (
      <div
        className="section badgePeculiarity"
        style={
          isExperienced
            ? { backgroundColor: "#eb5e32" }
            : { backgroundColor: "#f8a64b" }
        }
      >
        <div className="badge">
          <div className="identityInfoContainer">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleImageChange}
              />

              {modifyProfileImg ? (
                <div className="profileImgUpdateContainer">
                  <Cropper
                    src={
                      selectedProfileImg ? selectedProfileImg.uri : profileImage
                    }
                    // style={{ height: "30vh", width: "30vw" }}
                    className="profileImgUploader"
                    initialAspectRatio={4 / 4}
                    aspectRatio={4 / 4}
                    minCropBoxHeight={100}
                    minCropBoxWidth={100}
                    guides={false}
                    checkOrientation={false}
                    onInitialized={(instance) => {
                      setCropper(instance);
                    }}
                  />
                  <div className="profileUpdateImageButtonsContainer">
                    <div
                      className=" profileImgSaveButton"
                      onClick={async () => {
                        const croppedImg = await sendCrppedImage();
                        await handleUpload(croppedImg);
                        setModifyProfileImg(false);
                      }}
                    >
                      <span>Upload</span>
                    </div>
                    <div
                      className=" profileImgSaveButton"
                      onClick={() => {
                        setModifyProfileImg(false);
                        setSelectedProfileImg(null);
                      }}
                    >
                      <span>Back</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="profileImageSubContainer">
                  <img
                    src={
                      croppedProfileImg
                        ? croppedProfileImg.uri
                        : selectedProfileImg
                        ? selectedProfileImg.uri
                        : profileImage
                    }
                    className="profileImage"
                    alt="profileImage"
                    onClick={handleImageClick}
                  />
                </div>
              )}
            </div>
            <h2 className="name">
              {userData.firstName} {userData.lastName}
              {/* Supercalifragilistioc chespiralisotso */}
            </h2>
            <div className="secondaryInfoContainer">
              <MdLocationPin />
              <p className="secondaryInfo">{userData.city}</p>
            </div>
          </div>
          <div className="metricsContainer">
            <MetricDiv label={"NetCoins"} value={userData.netCoins} />
            <MetricDiv label={"Groups"} value={groupsCount} />
            {/* <MetricDiv label={"Projects"} value={0} /> */}
          </div>
          <div className="mainInfoContainer"></div>
          <div className="buttonParamContainer"></div>
        </div>
      </div>
    );
  };

  return (
    <div className="main">
      {isRewarded && (
        <RewardDeductionFunction
          increment={10}
          goal={"You completed your profile!"}
          userData={userData}
        />
      )}
      <Badge />
      <BioArea
        setModifyBio={setModifyBio}
        setSelectedBio={setSelectedBio}
        selectedBio={selectedBio}
        modifyBio={modifyBio}
      />
      {isExperienced ? (
        <ItemsViewerPicker
          sectionTitle={"Experience Fields"}
          sectionSubTitle={"Fields where you've personally run businesses"}
          nodeLabelTarget={"BusinessType"}
          relationLabelWithTarget={"HAS_EXPERIENCE_IN"}
          reduxStateVariableName={"businessExperience"}
          items={categories.businessTypes}
          stateSetterFunction={setModifyBusinessExp}
          stateVariable={modifyBusinessExp}
          selectedItems={selectedBusinessExp}
          setSelectedItems={setSelectedBusinessExp}
        />
      ) : (
        <div className="section">
          <input
            type="checkbox"
            checked={isExperienced}
            onChange={() => {
              setIsExperienced(true);
              setModifyBusinessExp(true);
            }}
            style={{ transform: "scale(1.5)" }}
          />
          <label
            htmlFor="checkbox"
            style={{
              fontSize: 22,
              fontWeight: "bold",
              color: "black",
              marginLeft: "3%",
            }}
          >
            Check if you've had any entrepreneurial experience yet
          </label>
        </div>
      )}
      <ItemsViewerPicker
        sectionTitle={"Business Interests"}
        sectionSubTitle={"Business fields that interest you"}
        nodeLabelTarget={"BusinessType"}
        relationLabelWithTarget={"IS_INTERESTED_IN"}
        reduxStateVariableName={"businessInterests"}
        items={categories.businessTypes}
        stateSetterFunction={setModifyBusinessInterests}
        stateVariable={modifyBusinessInterests}
        selectedItems={selectedBusinessInterest}
        setSelectedItems={setSelectedBusinessInterest}
      />
      <ItemsViewerPicker
        sectionTitle={"Skills"}
        sectionSubTitle={"Skills that define you the most"}
        nodeLabelTarget={"Skill"}
        relationLabelWithTarget={"IS_SKILLED_IN"}
        reduxStateVariableName={"skills"}
        items={categories.skills}
        stateSetterFunction={setModifySkill}
        stateVariable={modifySkill}
        selectedItems={selectedSkills}
        setSelectedItems={setSelectedSkills}
      />

      <ItemsViewerPicker
        sectionTitle={"Hobbies"}
        sectionSubTitle={"Your Hobbies"}
        nodeLabelTarget={"Hobbie"}
        relationLabelWithTarget={"IS_PASSIONATE_ABOUT"}
        reduxStateVariableName={"hobbies"}
        items={categories.hobbies}
        stateSetterFunction={setModifyHobbies}
        stateVariable={modifyHobbies}
        selectedItems={selectedHobbies}
        setSelectedItems={setSelectedHobbies}
      />
    </div>
  );
}
