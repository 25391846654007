import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import MatchingPeopleIterator from "../peopleResumeIterator";
import CustomClipperLoader from "../../../../hooks/CustomClipperLoader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  MdAddCircle,
  MdArrowBack,
  MdLocationPin,
  MdMessage,
} from "react-icons/md";

export default function CustomSearchPeopleResults() {
  const peopleSearchParams = useSelector(
    (state) => state.customSearchReducer.getPeopleParams
  );
  const groups = useSelector(
    (state) => state.fetchUsersGroupAndTeamsReducer.groups
  );
  const createdGroups = groups.filter((group) => group.type === "IS_CREATOR");
  const userDataId = useSelector((state) => state.fecthUserDataReducer.id);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [searchedPeople, setSearchedPeople] = useState(state.firstResults);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(2);
  const myRef = useRef(true);

  const loadPeople = useCallback(async () => {
    if (!hasMore) return;
    if (!myRef.current) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_NODEJS_URL}/getCustomSearchResults`,
          { ...peopleSearchParams, page: page, limit: 35 }
        );
        const results = Object.values(response.data);

        setSearchedPeople((prevPeople) => [...prevPeople, ...results]);

        // Set hasMore based on the number of results obtained
        setHasMore(results.length > 0);
      } catch (error) {
        console.error("Failed to fetch posts", error);
      }
    }
  }, [page, hasMore, peopleSearchParams]);

  useEffect(() => {
    loadPeople();
  }, [page, loadPeople]);

  const handleScroll = useCallback(() => {
    if (myRef.current) {
      myRef.current = false;
    }
    if (
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight &&
      hasMore
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [hasMore]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const getChatRoomId = (id1, id2) => {
    const sortedIds = [id1, id2].sort();

    // Concatena gli ID ordinati in un'unica stringa
    const combinedId = sortedIds[0] + sortedIds[1];
    return combinedId;
  };
  const goToChatPage = (item, userDataId, navigate) => {
    const room_id = getChatRoomId(item.user.id, userDataId);
    navigate(`/chat/${room_id}`, {
      state: { ...item.user, chatType: "private", room_id: room_id },
    });
  };

  return (
    <div className="mainPeople ">
      <div
        style={{ marginRight: "20px", display: "flex", alignItems: "center" }}
        onClick={() => {
          window.history.back();
        }}
      >
        <MdArrowBack size={30} />
      </div>
      <div>
        {!searchedPeople.length > 0 && <CustomClipperLoader />}
        {searchedPeople.length > 0 &&
          searchedPeople.map((item, index) => (
            <div className="section matchingSection " key={index}>
              <div className=" matchingProfileImgSub">
                <img
                  src={item.user.imgProfileLocation}
                  className=" matchingPeopleImg"
                  alt="matchingPeopleImg"
                />
              </div>
              <div className="matchingPeopleInfo">
                <div className="matchingPeopleMainInfo">
                  <h2 className="matchingNames">
                    {item.user.firstName} {item.user.lastName}
                  </h2>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "baseline",
                      width: "300px",
                      margin: "0 0 10px 5px",
                    }}
                  >
                    <p
                      // className="secondaryInfo"
                      style={{ alignItems: "baseline", fontWeight: "bold" }}
                    >
                      <MdLocationPin />
                      {item.user.city}
                    </p>
                    <p
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "#999999",
                        marginLeft: "2%",
                      }}
                    >
                      {Math.round(item.distance)} Km
                    </p>
                  </div>
                  <p>{item.user.bio}</p>
                  <div className="matchingPeopleButtons">
                    {createdGroups.length > 0 && (
                      <Link
                        to={`/match/groupInvitation/${item.id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <div className="matchingActionPeopleButton">
                          <MdAddCircle size={20} color="black" />
                        </div>
                      </Link>
                    )}

                    <div
                      className="matchingActionPeopleButton"
                      onClick={() => goToChatPage(item, userDataId, navigate)}
                    >
                      <MdMessage size={20} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
