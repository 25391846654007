import React, { useEffect, useRef, useState } from "react";
import "../../../../App.css";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Link, Outlet, useNavigate, useNavigation } from "react-router-dom";
import { fetchUsersMatched } from "../../../global-context/Slice";
import { useLocation } from "react-router-dom";
import { useSocket } from "../../../global-context/SocketContext";
import {
  MdFilter,
  MdFilter1,
  MdFilter9Plus,
  MdFilterAlt,
  MdFilterBAndW,
} from "react-icons/md";

export default function Match() {
  const symbol = require("../../../../assets/Symbol.png");
  const location = useLocation();
  const socket = useRef(useSocket());

  const pathSegments = location.pathname.split("/");
  const lastSegment = pathSegments[pathSegments.length - 1];
  const [isMatching, setIsMatching] = useState(false);
  const [pageSelected, setPageSelected] = useState(lastSegment);

  const [isUserDataCompleted, setIsUserDataCompleted] = useState(true);
  const userData = useSelector((state) => state.fecthUserDataReducer);
  const lastUpdateTs = useSelector(
    (state) => state.fetchUsersMatchedReducer.lastUpdateTs
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myRef = useRef(false);

  useEffect(() => {
    const checkUserData = () => {
      if (
        (userData.skills.length === 0) |
        (userData.businessInterests.length === 0) |
        (userData.hobbies.length === 0)
      ) {
        return false;
      } else {
        return true;
      }
    };
    if (!myRef.current) {
      setIsUserDataCompleted(checkUserData());
      myRef.current = true;
    }
  }, []);

  const handleMatching = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_MATCHING_API_URL,
        userData
      );
      const sorted_matching_users = response.data.people.sort(
        (a, b) => b.total_score - a.total_score
      );
      const sorted_matching_groups = response.data.groups.sort(
        (a, b) => b.total_score - a.total_score
      );

      const date = new Date();
      dispatch(
        fetchUsersMatched({
          lastUpdateTs: `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`,
          users: sorted_matching_users,
          groups: sorted_matching_groups,
        })
      );
      setPageSelected("people");
    } catch (error) {
      console.log(error);
      alert(`Qualcosa è andato storto:\n${error.message}`);
    } finally {
      setIsMatching(false);
    }
  };

  const startMatching = () => {
    setIsMatching(!isMatching);
    handleMatching();
  };

  const ProfileCompletionInvitation = () => {
    return (
      <div className="groupsCreateNew" style={{ marginTop: "0px" }}>
        <p style={{ margin: "15px" }}>
          It seems that your profile information is incomplete. To effectively
          run the matching algorithm, it's necessary to provide the following
          details: business interests, skills, and hobbies. Please ensure you
          fill in these fields in you profile page to enable accurate matching.
          Thank you !
        </p>
      </div>
    );
  };

  return (
    <div className="main ">
      <h2>Get your Matches</h2>
      <div className="matchNavBar">
        <Link
          to="people"
          onClick={() => setPageSelected("people")}
          className={`matchingSubPageSelectionButton ${
            pageSelected === "people"
              ? "matchingSubPageSelectionButtonActive"
              : ""
          }`}
        >
          <div>
            <p>People</p>
          </div>
        </Link>

        <Link
          to="groups"
          onClick={() => setPageSelected("groups")}
          className={`matchingSubPageSelectionButton ${
            pageSelected === "groups"
              ? "matchingSubPageSelectionButtonActive"
              : ""
          }`}
        >
          <div>
            <p>Groups</p>
          </div>
        </Link>

        {/* <Link
          to="teams"
          onClick={() => setPageSelected("teams")}
          className={`matchingSubPageSelectionButton ${
            pageSelected === "teams"
              ? "matchingSubPageSelectionButtonActive"
              : ""
          }`}
        >
          <div>
            <p>Teams</p>
          </div>
        </Link> */}
      </div>
      {pageSelected ? (
        <div>
          <div>
            {isUserDataCompleted === false && <ProfileCompletionInvitation />}
            {isUserDataCompleted === true && <Outlet />}
          </div>
          <div
            className="matchSymbolContainerSmall "
            onClick={() => setPageSelected(null)}
          >
            <img
              src={symbol}
              className="matchSymbolSmall"
              alt="matchSymbolSmall"
            />
          </div>
        </div>
      ) : (
        <div className="matchMain">
          {/* <div style={{ marginBottom: "40%" }}>
            <p>Last Update: {lastUpdateTs}</p>
          </div> */}
          <div
            style={{
              color: "#aaaaaa",
              fontWeight: "bold",
              margin: "30px",
              fontSize: "15px",
            }}
          >
            <p>Click here to custom your search</p>
          </div>

          <Link
            className="searchFilter"
            to={"/custom-search"}
            onClick={() => setPageSelected(null)}
          >
            <MdFilterAlt size={30} />
            <p>Custom Search</p>
          </Link>
          <hr style={{ width: "100%", margin: "30px" }} />
          <div
            style={{
              color: "#aaaaaa",
              fontWeight: "bold",
              fontSize: "15px",
              margin: "10px",
            }}
          >
            <p>Click here to update your matches</p>
          </div>

          <div
            className={`matchSymbolContainer ${isMatching ? "rotating" : ""}`}
            onClick={startMatching}
            style={{ marginTop: "10px" }}
          >
            <img src={symbol} className="matchSymbol" alt="matchSymbol" />
          </div>
        </div>
      )}
    </div>
  );
}
