import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { fetchUserData } from "../global-context/Slice";
import { MdClose } from "react-icons/md";
import { Button } from "antd";

export default function RewardDeductionFunction({
  increment,
  userData,
  goal = null,
  type = "win",
  continueFunction = () => {},
}) {
  const [isWindowOpen, setIsWindowOpen] = useState(true);
  const [insufficientNcWarning, setInsufficientNcWarning] = useState(null);
  const dispatch = useDispatch();
  const myRef = useRef(false);

  const updateNetCoins = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NODEJS_URL}/incrementInitializeNetCoins`,
        { id: userData.id, goal: goal || null, increment: increment }
      );
      const result = response.data;

      if (result) {
        const objectToUpload = {
          id: userData.id,
          netCoins: userData.netCoins + increment,
        };
        dispatch(fetchUserData(objectToUpload));
      }
    } catch (error) {
      console.error("Error updating goals and netCoins:", error);
    }
  };

  const checkNetCoins = () => {
    console.log(userData.netCoins + increment);
    if (userData.netCoins + increment < 0) {
      setInsufficientNcWarning(
        "Attention! You do not have enough netCoins to perform this request"
      );
      return false;
    } else {
      return true;
    }
  };

  const handleContinueFunction = () => {
    if (checkNetCoins()) {
      updateNetCoins();
      setIsWindowOpen(false);
      continueFunction();
    }
  };

  useEffect(() => {
    if (!myRef.current && type == "win") {
      updateNetCoins();
      myRef.current = true;
    }
  }, []);

  if (isWindowOpen) {
    if (type == "win") {
      return (
        <div className="popUpWindow">
          <div>
            <MdClose onClick={() => setIsWindowOpen(false)} />
          </div>
          <div>
            <h2>Congratulations!</h2>
            <p>You have received {increment} netCoins</p>
            <h3>Achieved Goal: </h3>
            <p>{goal}</p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="popUpWindow">
          <div>
            <MdClose onClick={() => setIsWindowOpen(false)} />
          </div>
          <div>
            <h2 style={{ margin: "0px 0px 10px 0px" }}>Pro feature!</h2>
            <p style={{ margin: "0px 0px 10px 0px"  }}>
              Use {-increment} NetCoins to continue!
            </p>
            <Button
              type="primary"
              style={{ backgroundColor: "#f8a64b", fontWeight: "bold" }}
              onClick={handleContinueFunction}
            >
              Continue
            </Button>
            {insufficientNcWarning && (
              <div>
                <p style={{margin:'10px 0px'}}>{insufficientNcWarning}</p>
                <Button
                  type="primary"
                  style={{ backgroundColor: "#b10491", fontWeight: "bold" }}
                  onClick={() => setIsWindowOpen(false)}
                >
                  Upgrade to Pro
                </Button>
              </div>
            )}
          </div>
        </div>
      );
    }
  }

  return null;
}
