import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  resetChatNumMessages,
  initializeChatArray,
} from "../../global-context/Slice";
import { useNavigate } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";

export default function ChatsViewer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const myRef = useRef(false);
  const chats = useSelector((state) => state.fetchChatReducer.chats);
  const defaultPersonPhoto = require("../../../assets/profilePictureDefault.png");
  const userLogInformations = useSelector(
    (state) => state.authReducer.userInfo
  );

  const getChats = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_NODEJS_URL}/getChats`,
      { collection_id: userLogInformations.id }
    );
    const chats = response.data;

    if (chats) {
      dispatch(
        initializeChatArray({
          chats: chats,
        })
      );
    }
  };

    useEffect(() => {
        getChats();
    // if (!myRef.current) {
    //     getChats();
    //   myRef.current = true;
    // }
  }, []);

  const goToChatRoom = (item) => {
    const state = {
      chatType: item.chatType,
      room_id: item.roomId,
      firstName: item.chatType === "private" ? item.name : "",
      lastName: "",
      title: item.chatType === "group" ? item.name : "",
      id: item.id,
    };
    navigate(`/chat/${item.roomId}`, {
      state: state,
    });
  };

  const getDate = (dateTime) => {
    const year = dateTime.getFullYear();
    const month = String(dateTime.getMonth() + 1).padStart(2, "0");
    const day = String(dateTime.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getTime = (dateTime) => {
    const hours = String(dateTime.getHours()).padStart(2, "0");
    const minutes = String(dateTime.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const setChatLastUpdateTime = (time) => {
    const now = new Date();
    const chatDateTime = new Date(time);
    const currentDate = getDate(now);
    const chatDate = getDate(chatDateTime);
    const chatTime = getTime(chatDateTime);

    if (currentDate == chatDate) {
      return chatTime;
    } else if (Math.abs(now.getDate() - chatDateTime.getDate()) === 1) {
      return "Yesterday";
    } else {
      return chatDate;
    }
  };

  const ChatsIterator = ({ chatsToIterate }) => {
    return chatsToIterate.map((item, index) => (
      <div
        key={index}
        onClick={() => {
          goToChatRoom(item);
          dispatch(resetChatNumMessages(item));
        }}
      >
        <div className="chatContainer">
          <div className="chatContainerPhoto">
            <img
              src={item.chatImg ? item.chatImg : defaultPersonPhoto}
              className="chatImg"
              alt="chatImg"
            />
          </div>
          <div className="chatContainerMainInfo">
            <p
              style={{
                marginLeft: "5px",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              {item.name}
            </p>
            <p className="chatContainerLastMessage">{item.lastMessage}</p>
          </div>
          <div className="chatContainerDetails">
            {item.unreadMessages > 0 ? (
              <p className="newMessagesCircle">{item.unreadMessages}</p>
            ) : undefined}
            <p
              style={{
                fontSize: "15px",
                color: "#777777",
              }}
            >
              {setChatLastUpdateTime(item.updatedAt)}
            </p>
          </div>
        </div>
        <hr />
      </div>
    ));
  };

  return (
    <div style={{ margin: "10px", width:'95vw' }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{ marginRight: "20px", display: "flex", alignItems: "center" }}
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBack size={30} />
        </div>
        <h2>Chats</h2>
      </div>
      <div style={{ marginTop: "40px" }}>
        <ChatsIterator chatsToIterate={chats} />
      </div>
    </div>
  );
}
