import React, { useEffect, useRef } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { logIn } from "../global-context/Slice";
import { useDispatch } from "react-redux";
import CustomClipperLoader from "../hooks/CustomClipperLoader";

// import Monitoring from "../hooks/useMonitroing";

const RequireAuth = ({ Parse }) => {
  const dispatch = useDispatch();
  const userLogInformations = useSelector((state) => state.authReducer);
  const myRef = useRef(false);

  useEffect(() => {
    if (!myRef.current) {
      const dispatchInfoIfAlreadyLoggedIn = async () => {
        const current = await Parse.User.current();

        let sessionToken = null;
        if (current != null) {
          sessionToken = await current.getSessionToken();
        }
        const currentUser = {
          current: current,
          sessionToken: sessionToken ? sessionToken : null,
        };
        return currentUser;
      };

      const fetchUserInfo = async () => {
        const userInfo = await dispatchInfoIfAlreadyLoggedIn();
        if (userInfo.sessionToken) {
          // Monitoring({
          //   id: userInfo?.id,
          //   action: "LOGIN - REFRESH",
          //   additionalInfo: {},
          // });
          dispatch(
            logIn({
              userInfo: { ...userInfo?.current },
              sessionToken: userInfo?.sessionToken,
            })
          );
        } else {
          dispatch(
            logIn({
              userInfo: {},
              sessionToken: null,
            })
          );
        }
      };

      fetchUserInfo();
      myRef.current = true;
    }
  }, []);

  if (!userLogInformations.isLoaded) {
    return (
      <div>
        <div className="header">
          <img
            className="header_logo"
            alt="Doinit Logo"
            src={require("../../assets/logo.png")}
          />
        </div>
        <CustomClipperLoader />
      </div>
    );
  }

  if (userLogInformations.sessionToken) {
    return <Outlet />;
  }
  {
    return <Navigate to="/HomePage" />;
  }
};

export default RequireAuth;
