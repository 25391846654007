import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Button, Input } from "antd";
import CitySelector from "../../../hooks/CityPicker";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";

export default function EditMainInfo() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.fecthUserDataReducer);

  const [firstName, setFirstName] = useState(userData.firstName);
  const [lastName, setLastName] = useState(userData.lastName);
  const [city, setCity] = useState(userData.city);
  const [position, setPosition] = useState(userData.position);
  const sendUpdateToNeo4j = async (object) => {
    await axios.post(
      `${process.env.REACT_APP_NODEJS_URL}/createUpdate`,
      object
    );
  };

  const handleEditSaving = async () => {
    const editedFields = {
      id: userData.id,
      firstName: firstName,
      lastName: lastName,
      city: city,
      position: position,
    };
    await sendUpdateToNeo4j(editedFields);
    // dispatch(fetchUserData(editedFields));
    window.location.reload();
    navigate("/");
  };

  return (
    <div style={{ margin: "20px" }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{ marginRight: "20px", display: "flex", alignItems: "center" }}
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBack size={30} />
        </div>
        <h2>Edit Your Main Info</h2>
      </div>
      <div className="form_wrapper">
        <Input
          className="bioInput"
          style={{ marginTop: "10px", paddingLeft: "15px" }}
          value={firstName}
          onChange={(event) => setFirstName(event.target.value)}
        />
        <Input
          className="bioInput"
          style={{ marginTop: "10px", paddingLeft: "15px" }}
          value={lastName}
          onChange={(event) => setLastName(event.target.value)}
        />

        <div style={{ marginTop: "10px" }}>
          <CitySelector
            initialValue={city}
            functionCityStateVar={setCity}
            functionPositionStateVar={setPosition}
            style={'bioInput'}
          />
        </div>

        <div
          className="form_buttons"
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={() => handleEditSaving()}
            type="primary"
            className="form_button"
            style={{ width: "150px", marginTop: "40px" }}
            color={"#208AEC"}
            size="large"
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}
