import React from "react";
import "../../../../App.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import CustomClipperLoader from "../../../hooks/CustomClipperLoader";
import MatchingPeopleIterator from "./peopleResumeIterator";

export default function People() {
  const usersMatched = useSelector((state) => state.fetchUsersMatchedReducer);
  const userDataId = useSelector((state) => state.fecthUserDataReducer.id);
  const navigate = useNavigate();


  return (
    <div className="mainPeople ">
      <div>
        {!usersMatched.users.length > 0 && <CustomClipperLoader />}
        {usersMatched.users.length > 0 && (
          <MatchingPeopleIterator
            matchingPeople={usersMatched.users}
            userDataId={userDataId}
            navigate={navigate}
            buttonsEnabled = {true}
          />
        )}
      </div>
    </div>
  );
}
