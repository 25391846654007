import { configureStore } from "@reduxjs/toolkit";
import {
  
  authReducer,
  fecthUserDataReducer,
  fetchUsersMatchedReducer,
  fetchUsersGroupAndTeamsReducer,
  fecthCategoriesReducer,
  fecthNotificationReducer,
  fetchChatReducer,
  customSearchReducer
} from "./Slice";

export const Store = configureStore({
  reducer: {
    authReducer,
    fecthUserDataReducer,
    fetchUsersMatchedReducer,
    fetchUsersGroupAndTeamsReducer,
    fecthCategoriesReducer,
    fecthNotificationReducer,
    fetchChatReducer,
    customSearchReducer
  },
});
