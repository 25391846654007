import { Outlet } from "react-router-dom";
import RequireAuth from "./RequireAuth";

const Layout = ({ Parse }) => {
  return (
    <main className="App">
      <RequireAuth Parse={Parse} />
    </main>
  );
};

export default Layout;
