import React, { createContext, useContext } from "react";
import { io } from "socket.io-client";
import { useSelector } from "react-redux";

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const userId = useSelector((state) => state.fecthUserDataReducer.id);
  const URL_SOCKET = `${process.env.REACT_APP_SOCKET_URL}`  // + `/:${process.env.REACT_APP_SOCKET_PORT}`
  const socket = io(URL_SOCKET, {
  query: { userId: userId },
  transports: ["websocket"]
  });
  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);
